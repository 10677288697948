import '../App.css';
import Modal from 'react-bootstrap/Modal';

import React, { useEffect, useState, Fragment, useCallback } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'
import { PinItem } from '../components/PinItem';
import Icon from '../Icon';
import { PinChooser } from '../components/PinChooser';
import UnsplashReact, { Base64Uploader, withDefaultProps } from "unsplash-react"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useQueryClient } from 'react-query'


import uuid from 'react-uuid'
import { FileUploader } from "react-drag-drop-files";

import {
  Button, Row, Col, FloatingLabel, Alert
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { Map, Marker, useMap } from 'react-mapkit'
import 'react-bootstrap-typeahead/css/Typeahead.css';


import { API_URL } from '../vars'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function AddToCollection({ visible, onClose, collection, collectionSelectedPins, collectionSelectedTags }) {

  const { jwt, pins, tags } = React.useContext(PinDropContext)

  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [privacy, setPrivacy] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [showError, setShowError] = useState(false)
  const [localPhotos, setLocalPhotos] = useState([]);
  const [remotePhotos, setRemotePhotos] = useState([]);
  const [selectedPins, setSelectedPins] = useState([]);
  const [saving, setSaving] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false)
  const queryClient = useQueryClient()

  const [allSelectedPins, setAllSelectedPins] = useState([]);


  React.useEffect(() => {

    let lpins = []
    selectedTags.map(tag => {
      lpins = lpins.concat(pins.filter(pin => pin.tag_ids.includes(tag.id)))
    }
    )

    lpins = lpins.concat(selectedPins)
    setAllSelectedPins(lpins)

  }, [selectedPins, selectedTags])





  const handleSubmit = (e) => {



    setSaving(true)



    let bulkToOrg = []

    console.log("Trying to save", allSelectedPins)
    allSelectedPins.map(pin => {

      try {
        tags.filter(tag => pin.tag_ids.includes(tag.id)).map(tag => {
          if (bulkToOrg.filter(b => b.id === tag.id).length === 0) {
            bulkToOrg.push({ "name": tag.name, "color": tag.color, "id": tag.id, "type": "tag" })
          }
        })

        bulkToOrg.push({
          "name": pin.name, "id": pin.id, "type": "pin", "description": pin.description,
          "lat": pin.lat, "lng": pin.lng, "heroImage": pin.heroImage,
          "pinTemplate": "1", "extended": [], "tag_ids": pin.tag_ids, "date_dropped": pin.date_dropped,
          "date_updated": pin.date_updated
        }
        )

      } catch (error) {
        console.log("Error", error)
      }
    })

    selectedTags.map(tag => {
      if (bulkToOrg.filter(b => b.id === tag.id).length === 0) {

        bulkToOrg.push({ "name": tag.name, "color": tag.color, "id": tag.id, "type": "tag" })
      }
    })


    fetch(`${API_URL}/orgPost`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "token": jwt,
        "orgID": collection.id,
        "entities": bulkToOrg,
      })
    })
      .catch((err) => {
        console.log("Error uploading pins", err)
      }).finally(() => {
        queryClient.invalidateQueries(['collections']).then(() => {
          queryClient.refetchQueries(['collections']).then(() => {
            setSaving(false)

            onClose()
          })
        })
        console.log("done uploading")
      })






  }


  React.useEffect(() => {
    if (collection && collectionSelectedPins && collectionSelectedTags) {
      setName(collection.name)
      setDescription(collection.description)
      setPrivacy(collection.privacy)
      setSelectedTags(collectionSelectedTags)
      setSelectedPins(collectionSelectedPins)
    }
  }, [collectionSelectedPins, collectionSelectedTags, collection])

  return (
    <>


      <Modal show={visible} onHide={() => onClose()}
        dialogClassName={collection.backgroundPhoto?.length > 0 ? "modal-70w" : "modal-50w"}
        animation={false}
      >
        <Modal.Header closeButton>

          {collection.isCrew &&
            <Modal.Title>
              Add to Group
            </Modal.Title>
          }
          {collection.isCollection &&
            <Modal.Title>
              Add to Collection
            </Modal.Title>
          }
        </Modal.Header>
        <Modal.Body >
          {showError &&
            <Alert key={"danger"} variant={"danger"}>
              There was a problem saving your collection. Please try again later, or <Alert.Link href="mailto:hello@pindropapp.com" >contact us</Alert.Link> if this keeps happening.
            </Alert>
          }
          <Form>
            <Row>
              {collection.backgroundPhoto?.length > 0 &&
                <Col lg={6}>




                  <Form.Group className="mb-3" controlId="formBasicEmail">

                    <div class="d-flex align-items-center">
                      <div class="d-flex justify-content-center">



                        <div style={{ position: "relative", color: "red" }}>

                          <div style={{ position: "absolute", zIndex: 999, bottom: 16, left: 16 }}>
                            <div style={{ color: "white", fontWeight: "700", fontSize: 24 }}>{name}</div>
                            <div style={{ color: "white", fontWeight: "400", fontSize: 13, alignContent: "center" }}>
                              {privacy == "Private" &&
                                <Icon icon="Lock" size={20} color="white" />
                              }

                              {collection.isCollection ? "Collection" : "Group"} · {allSelectedPins.length} {allSelectedPins.length == 1 ? "pin" : "pins"}</div>
                          </div>
                          {collection && collection.backgroundPhoto?.length > 0 &&
                            <img src={`https://pindropbackup.blob.core.windows.net/${collection.id}/${collection.backgroundPhoto}`}
                              class="img-fluid"
                              onClick={() => {
                                console.log("Resest photo")
                              }
                              }
                              style={{
                                objectFit: "cover",
                                borderRadius: 8, borderWidth: 1, borderColor: "gray", marginRight: 12, height: "100%", width: "100%",
                                filter: "brightness(50%)"
                              }} />
                          }
                        </div>


                      </div>
                    </div>
                    {/* </Tab>
                  <Tab eventKey="Unsplash" title="Unsplash">

<div class="d-flex align-items-center">
<UnsplashReact
  columns={4}
accessKey={"nQccaH-rGQ-7iCLEtyhz6onx-yfka9S880Vg1swYrO0"}
Uploader={withDefaultProps(Base64Uploader, { name: "event[logo]" })}
/>
</div>
  </Tab>

</Tabs> */}

                  </Form.Group>

                </Col>
              }
              <Col lg={collection.backgroundPhoto?.length > 0 ? 6 : 12} >

                <PinChooser
                  setSelectedPins={(e) => setSelectedPins(e)}
                  setSelectedTags={(e) => setSelectedTags(e)}
                  selectedPins={selectedPins}
                  selectedTags={selectedTags}
                />

              </Col>
            </Row>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => onClose()}>
            Cancel
          </Button>

          <Button variant="primary" onClick={() => handleSubmit()} disabled={saving}>
            {saving ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>

      </Modal>

    </>
  );
}