import '../App.css';
import { Map, Marker } from 'react-mapkit'
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'
import { best } from 'wcag-color'
import { getDistance } from 'geolib';
import Icon from "../Icon";
import Form from 'react-bootstrap/Form';

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function TagItem({ tag, onPress, pinCard, selectable, checked }) {

  const { pins, tags, relationships, location, jwt } = React.useContext(PinDropContext)

  const number_of_pins = relationships.filter(item => item.tag_id == tag.id).length
  return (
    <div className={"d-flex  align-items-center"} style={{cursor: "pointer", paddingTop: 8, paddingBottom: 8, marginRight: 16}} key={tag.id} onClick={(tag) => onPress(tag)}>
      <div className={'d-flex '}>
        <div className={"align-items-center justify-content-center d-flex"} style={{ width: 64, height: 64, marginRight: 16, borderRadius: 8, backgroundColor: tag.color }}>
          {!tag.icon &&
          <Icon icon={"Tag"} size={40} color={best('#ffffff', '#3A3E44', tag.color)} />
          }

          {tag.icon &&
           <i class={`fa-solid fa-${tag.icon}`} style={{ color: best('#ffffff', '#3A3E44', tag.color)}} ></i>
          }
        </div>
      </div>
      <div className={"d-flex flex-column align-center w-100"} >
        <span style={{ fontWeight: "600", fontSize: 17, color: "#3A3E44", lineHeight: 1, paddingBottom: 4 }}>
          {tag.name}
        </span>
        <span style={{ fontSize: 13, color: "#3A3E44", lineHeight: 1, paddingBottom: 2 }}>
          {number_of_pins} {number_of_pins == 1 ? "pin" : "pins"}
        </span>

      </div>
      {selectable &&
        <div class="p-2" >
          <Form.Check
            type={"checkbox"}
            id={tag.id}
            checked={checked}
          />

        </div>
      }
    </div>
  );
}