import '../App.css';
import Modal from 'react-bootstrap/Modal';

import React, { useEffect, useState, Fragment } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'


import {
  Button, FloatingLabel, Row, Col, InputGroup
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import Switch from "react-switch";

import { API_URL } from '../vars'



var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function Settings({ visible, onClose }) {


  const { jwt, tags, setTags, miles, setMiles } = React.useContext(PinDropContext)


  const [saving, setSaving] = useState(false)
  const [name, setName] = useState("")
  const [color, setColor] = useState({ "hex": `#${Math.floor(Math.random() * 16777215).toString(16)}` })

  const [deleting, setDeleting] = useState(false)

  const handleDelete = (e) => {


  }



  return (
    <>


      <Modal show={visible} fullscreen={false} onHide={() => onClose()} centered


      // dialogClassName="modal-70w"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ width: "100%", justifyItems: "center" }}>
            Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>How should sitance be measured?</Form.Label>

              <div class="d-flex justify-content-between">
                <div class=" d-flex justify-content-start formLabelSwitch">
                  {miles ? "Miles" : "Kilometers"}
                </div>
                <div class="d-flex justify-content-end">
                  <Switch width={51} height={31} onColor='#54AD95' onChange={(val) => setMiles(val)} checked={miles} uncheckedIcon={false} checkedIcon={false} />
                </div>
              </div>
            </Form.Group>


            <Modal.Footer>



              <Button variant="outline-dark" onClick={() => onClose()}>
                Done
              </Button>
            </Modal.Footer>


          </Form>


        </Modal.Body>


      </Modal>

    </>
  );
}