import '../App.css';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import Icon from "../Icon";
import { PinDropContext } from '../Context'
import { PinItem } from './PinItem';
import { NewTag } from './NewTag';
import { AddToTag } from './AddToTag';
import { Pin } from './Pin';
import {
  Button, Row, Col
} from 'react-bootstrap';
import { best } from 'wcag-color';
import Offcanvas from 'react-bootstrap/Offcanvas';

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function Tag({ tag, onClose, map, mapkit }) {

  const { jwt, pins, tags, relationships, loadingPins, setLoadingPins } = React.useContext(PinDropContext)

  const [selectedPin, setSelectedPin] = useState(null)
  const [editTag, setEditTag] = useState(null)
  const [addToTag, setAddToTag] = useState(false)

  useEffect(() => {
    if (map && selectedPin) {
      var newCenter = new mapkit.Coordinate(selectedPin.lat, selectedPin.lng);
      var span = new mapkit.CoordinateSpan(.01);
      var region = new mapkit.CoordinateRegion(newCenter, span);
      map.setRegionAnimated(region)
      if (selectedPin) {
        let ann = map.annotations.find(a => a.data === selectedPin.id)
       ann.selected = true
      } else {
        map.annotations.forEach(a => a.selected = false)
      }
    }

  }, [selectedPin, map])


  const onShare = () => {
    if (navigator.share) {
      navigator.share({
        title: tag.name,
        text: 'Check out web.dev.',
        url: 'https://pindrop.me/tag/' + tag.id
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }

  }


  return (
    <div style={{ padding: 16 }}>

      <div
        className="d-flex"
        style={{
          flexDirection: "column",
          // filter: "brightness(50%)",
          // color: "red",
          backgroundSize: "cover",
          backgroundColor: tag.color,
          padding: 16,
          marginBottom: 4,
          borderRadius: 12, width: "100%", height: 250
        }}

      >
        <Row className="h-100">
          <Col >
            <a href='#' onClick={() => onShare()} >
              <Icon icon="Share" size={40} color={best('#ffffff', '#3A3E44', tag.color)} />
            </a>
          </Col>
          <Col className="d-flex" style={{ justifyContent: "flex-end" }}>
            <a href='#' onClick={() => onClose()} >
              <Icon icon="CollapseRight" size={40} color={best('#ffffff', '#3A3E44', tag.color)} />
            </a>
          </Col>
        </Row>

        <Row >
          <Col>
            <div className='title' style={{ color: best('#ffffff', '#3A3E44', tag.color) }}>
              {tag.name}
            </div>
            <div className='subTitle' style={{ color: best('#ffffff', '#3A3E44', tag.color) }}>
              {pins.filter(p => p.tag_ids.includes(tag.id)).length} {pins.filter(p => p.tag_ids.includes(tag.id)).length == 1 ? "pin" : "pins"}
            </div>

            <div className="w-100 d-flex" style={{ marginTop: 8 }}>

              <Col style={{ marginRight: 5, paddingLeft: 0 }}>
                <Button onClick={() => setEditTag(tag)} className="w-100"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.4)",
                    borderWidth: 0

                  }}>
                  <Icon icon="Edit" size={40} color={'white'} />
                </Button>
              </Col>

              <Col style={{ marginLeft: 5, paddingLeft: 0 }}>
                <Button onClick={() => setAddToTag(true)} className="w-100"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.4)",
                    borderWidth: 0

                  }}>
                  <Icon icon="RoundPlus" size={40} color={'white'} />
                </Button>
              </Col>
            </div>
          </Col>

        </Row>


      </div>

      <div class="pinListContainer">

        <div class="pinListTags">
          {!loadingPins && pins && relationships && tags && pins.filter(p => p.tag_ids.includes(tag.id)).map((pin, i) => {
            return (
              <PinItem
                pin={pin}
                onPress={() => {
                  setSelectedPin(pin)
                }}

              />
            )
          })
          }
        </div>
      </div>



      {editTag &&
        <NewTag onClose={() => setEditTag(false)} tag={tag} visible={editTag} />
      }

      {addToTag &&
        <AddToTag show={addToTag}
          onClose={(deleted) => {
            setAddToTag(false);

          }}
          visible={addToTag}
          tag={tag}
          tagSelectedPins={[]}
        />
      }


      <Offcanvas show={selectedPin}
        placement={"end"}
        backdrop={false}
        onHide={() => {
          setSelectedPin(null);

          // setShowSearch(true);

        }}
        style={{ width: 410, padding: 0 }}>
        <Offcanvas.Body style={{ padding: 0 }}>
          {selectedPin &&
            <>

              <Pin pin={selectedPin} onClose={() => {
                setSelectedPin(null);
              }} />
            </>
          }
        </Offcanvas.Body>
      </Offcanvas>

    </div>

  );
}