import '../App.css';
import Modal from 'react-bootstrap/Modal';

import React, { useEffect, useState, Fragment, useCallback } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'
import { PinChooser } from '../components/PinChooser';
import { useQueryClient } from 'react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { best } from 'wcag-color'

import ReactTags from 'react-tag-autocomplete'
import fileDownload from 'js-file-download'



import {
  Button, Row, Col, Alert
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import 'react-bootstrap-typeahead/css/Typeahead.css';


import { API_URL } from '../vars'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
var _ = require('lodash');

export function BulkEdit({ visible, onClose, collection, collectionSelectedPins, collectionSelectedTags }) {

  const { jwt, pins, tags, setPins, setTags, setMedia, setRelationships, rBush } = React.useContext(PinDropContext)
  const reactTags = React.useRef()

  const [groups, setGroups] = useState([])
  const [collections, setCollections] = useState([])

  const [selectedTags, setSelectedTags] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [selectedCollections, setSelectedCollections] = useState([])

  const [showError, setShowError] = useState(false)

  const [selectedPins, setSelectedPins] = useState([]);
  const [saving, setSaving] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [tagsToDelete, setTagsToDelete] = useState([]);
  const [tagsToAdd, setTagsToAdd] = useState([]);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const queryClient = useQueryClient()



  React.useEffect(() => {


    const tag_ids = selectedPins.map(pin => pin.tag_ids)

    const sTags = tag_ids.flat()
    let uTags = _.uniq(sTags);

    setSelectedTags(tags.filter(t => uTags.includes(t.id)))
    console.log(uTags)

  }, [selectedPins])



  const onTagsDelete = useCallback((tag) => {
    setSelectedTags(selectedTags.filter(i => i.id !== tag.id))
    setTagsToDelete(tagsToDelete.concat(tag))
  }, [selectedTags])

  const onTagsAddition = useCallback((newTag) => {
    setSelectedTags([...selectedTags, newTag])
    setTagsToAdd(tagsToAdd.concat(newTag))

  }, [selectedTags])

  const onGroupsDelete = useCallback((tag) => {
    setSelectedGroups(selectedGroups.filter(i => i.id !== tag.id))
  }, [selectedGroups])

  const onGroupsAddition = useCallback((newTag) => {
    setSelectedGroups([...selectedGroups, newTag])
  }, [selectedGroups])

  const onCollectionsDelete = useCallback((tag) => {
    setSelectedCollections(selectedCollections.filter(i => i.id !== tag.id))
  }, [selectedCollections])

  const onCollectionsAddition = useCallback((newTag) => {
    setSelectedCollections([...selectedCollections, newTag])
  }, [selectedCollections])


  function SuggestionComponent({ item, query }) {
    const ltag = tags.find(t => t.id == item.id)

    return (
      <span key={ltag.id} className={item.name === query ? 'match' : 'no-match'} style={{ color: best('#ffffff', '#3A3E44', ltag.color), fontSize: 13, marginRight: 4, backgroundColor: ltag.color, paddingRight: 8, paddingLeft: 8, paddingTop: 4, paddingBottom: 2, borderRadius: 4, marginBottom: 4 }}>
        {ltag.name}
      </span>
    )
  }

  React.useEffect(() => {
    if (visible) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: jwt })
      };

      fetch('https://apiv2.pindrop.it/getOrg', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log("ORGS", data)
          setGroups(data.filter(g => (g.isCrew && g.isOwner)));
          setCollections(data.filter(g => (g.isCollection && g.isOwner)));
        });
    }
  }, [visible, jwt])


  const refreshAll = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: jwt })
    };
    fetch(`${API_URL}/webData`, requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log(data)

        rBush.clear()
        for (let index = 0; index < data["pins"].length; index++) {
          const pin = data["pins"][index];
          let pin_tags = data["relationships"].filter(item => item.pin_id == pin.id).map(tags => tags.tag_id)

          rBush.insert({
            minX: pin.lat, maxX: pin.lat, minY: pin.lng, maxY: pin.lng, id: pin.id,
            name: pin.name, lat: pin.lat, lng: pin.lng, heroImage: pin.heroImage, tag_ids: pin_tags,
            date_dropped: pin.date_dropped, date_updated: pin.date_updated, isSecret: pin.isSecret,
            favourite: pin.favourite, description: pin.description ? pin.description : "",
            orgId: pin.orgId, isOrg: pin.isOrg == 1 ? true : false, isCollection: pin.isCollection == 1 ? true : false,
            isCrew: pin.isCrew == 1 ? true : false, isOwner: pin.isOwner == 1 ? true : false, canEdit: pin.canEdit == 1 ? true : false
          })

        }

        setPins(rBush.all())

        setTags(data["tags"])
        setRelationships(data["relationships"])
        setMedia(data["media"])

        if (selectedGroups.length > 0) {
          selectedGroups.map(g => {
            saveToOrg(g.id, rBush.all(), data["tags"])
          })
        }

        if (selectedCollections.length > 0) {
          selectedCollections.map(c => {
            saveToOrg(c.id, rBush.all(), data["tags"])
          })
        }



      }).finally(() => {
        if (selectedGroups.length == 0 && selectedCollections.length == 0) {
          setSaving(false)
        }
        console.log("refreshed All")
      })


  }


  const handleExport = (e) => {
    setExporting(true)

    fetch(`${API_URL}/export`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "token": jwt,
        "pins": selectedPins.map(p => p.id),
      })
    }).then(response => response.blob()).then(blob => {
      fileDownload(blob, "PindropExport.xlsx")
    })
      .catch((err) => {
        console.log("Error exporting pins", err)
      }).finally(() => {
        // refreshAll()
        setExporting(false)
      })

  }

  const saveToOrg = (orgID, pins, tags) => {



    setSaving(true)



    let bulkToOrg = []

    selectedPins.map(pin => {

      tags.filter(tag => pin.tag_ids.includes(tag.id)).map(tag => {
        if (bulkToOrg.filter(b => b.id === tag.id).length === 0) {
          console.log("TAG STO", tag)
          bulkToOrg.push({ "name": tag.name, "color": tag.color, "id": tag.id, "type": "tag" })
        }
      })

      console.log("PIN STO", pin.name)

      bulkToOrg.push({
        "name": pin.name, "id": pin.id, "type": "pin", "description": pin.description,
        "lat": pin.lat, "lng": pin.lng, "heroImage": pin.heroImage,
        "pinTemplate": "1", "extended": [], "tag_ids": pin.tag_ids, "date_dropped": pin.date_dropped,
        "date_updated": pin.date_updated
      }
      )
    })

    // selectedTags.map(tag => {
    //   if (bulkToOrg.filter(b => b.id === tag.id).length === 0) {
    //     bulkToOrg.push({ "name": tag.name, "color": tag.color, "id": tag.id, "type": "tag" })
    //   }
    // })


    fetch(`${API_URL}/orgPost`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "token": jwt,
        "orgID": orgID,
        "entities": bulkToOrg,
      })
    })
      .catch((err) => {
        console.log("Error uploading pins", err)
      }).finally(() => {

        console.log("Saved to Org!")
        queryClient.invalidateQueries(['collections']).then(() => {
          queryClient.refetchQueries(['collections'])
        })

        queryClient.invalidateQueries(['groups']).then(() => {
          queryClient.refetchQueries(['groups'])
        })
        console.log("done uploading")

        setSaving(false)
        onClose()
      })






  }


  const handleDelete = (e) => {
    setDeleting(true)

    fetch(`${API_URL}/saveWebData`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "token": jwt,
        "pins": selectedPins.map(p => p.id),
        "tags": selectedTags.map(t => t.id),
        "type": "delete"
      })
    })
      .catch((err) => {
        console.log("Error uploading pins", err)
      }).finally(() => {
        setSelectedPins([])
        setSelectedTags([])
        setConfirmDelete(false)
        refreshAll()
        setDeleting(false)
      })

  }

  const handleSubmit = (e) => {
    setSaving(true)

    fetch(`${API_URL}/saveWebData`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "token": jwt,
        "pins": selectedPins.map(p => p.id),
        "tags": selectedTags.map(t => t.id),
        "type": "bulk"
        // "groups": selectedGroups.map(g => g.id),
        // "collections": selectedCollections.map(c => c.id),
      })
    })
      .catch((err) => {
        console.log("Error uploading pins", err)
      }).finally(() => {
        refreshAll()
      })

  }






  function TagComponent({ tag, removeButtonText, onDelete }) {
    const ltag = tags.find(t => t.id == tag.id)
    return (
      <span key={tag.id} onClick={() => onTagsDelete(tag)} style={{ color: best('#ffffff', '#3A3E44', ltag.color), fontSize: 13, marginRight: 4, backgroundColor: ltag.color, paddingRight: 8, paddingLeft: 8, paddingTop: 2, paddingBottom: 2, borderRadius: 4, marginBottom: 4 }}>
        {ltag.name}
        <FontAwesomeIcon icon={light('xmark')} style={{ color: best('#ffffff', '#3A3E44', ltag.color), fontSize: 13, marginLeft: 4 }} />
      </span>

    )
  }

  function CollectionComponent({ tag, removeButtonText, onDelete }) {
    console.log("tag", tag)
    const lOrg = collections.find(t => t.id === tag.id)
    return (
      <span key={tag.id}
        onClick={() => onCollectionsDelete(tag)}
        style={{ color: "white", fontSize: 13, marginRight: 4, backgroundColor: "#3A3E44", paddingRight: 8, paddingLeft: 8, paddingTop: 2, paddingBottom: 2, borderRadius: 4, marginBottom: 4 }}>
        {tag.name}
        <FontAwesomeIcon icon={light('xmark')} style={{ color: "white", fontSize: 13, marginLeft: 4 }} />

      </span>

    )
  }

  function GroupComponent({ tag, removeButtonText, onDelete }) {
    console.log("tag", tag)

    return (
      <span
        onClick={() => onGroupsDelete(tag)}

        key={tag.id} style={{ color: "white", fontSize: 13, marginRight: 4, backgroundColor: "#3A3E44", paddingRight: 8, paddingLeft: 8, paddingTop: 2, paddingBottom: 2, borderRadius: 4, marginBottom: 4 }}>
        {tag.name}
        <FontAwesomeIcon icon={light('xmark')} style={{ color: "white", fontSize: 13, marginLeft: 4 }} />

      </span>

    )
  }

  return (
    <>


      <Modal show={visible} onHide={() => onClose()}
        dialogClassName="modal-70w"
        animation={false}
        overlayClassName="modal-backdrop"
        style={{ filter: confirmDelete ? "brightness(50%)" : "brightness(100%)" }}
        centered
      >
        <Modal.Header closeButton>


          <Modal.Title>
            Bulk edit
          </Modal.Title>

        </Modal.Header>
        <Modal.Body >
          {showError &&
            <Alert key={"danger"} variant={"danger"}>
              There was a problem saving your collection. Please try again later, or <Alert.Link href="mailto:hello@pindropapp.com" >contact us</Alert.Link> if this keeps happening.
            </Alert>
          }
          <Form>
            <Row>
              <Col lg={6} >

                <PinChooser
                  setSelectedPins={(e) => setSelectedPins(e)}
                  setSelectedTags={(e) => setSelectedTags(e)}
                  selectedPins={selectedPins}
                  // selectedTags={selectedTags}
                  bulk
                />

              </Col>
              <Col lg={6}>






                <div class="d-flex justify-content-center align-items-center" style={{ flexDirection: "column", padding: 32 }}>
                  {selectedPins.length == 0 &&
                    <>
                      <span className='title'>0 pins selected</span>
                      <span className='subtitle'>Bulk edit pins</span>
                    </>
                  }

                  {selectedPins.length > 0 &&
                    <div className='w-100'>
                      <div class="d-flex justify-content-center align-items-center" style={{ flexDirection: "row" }}>
                        <p className='title'>{selectedPins.length} {selectedPins.length == 1 ? "pin" : "pins"} selected</p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center" style={{ flexDirection: "row" }}>
                        <Button size='lg' disabled={deleting} variant="outline-danger" onClick={() => setConfirmDelete(true)} style={{ marginRight: 16 }}>
                          <FontAwesomeIcon icon={light('trash-can')} size={32} style={{ paddingRight: 4 }} />
                          {deleting ? "Deleting..." : "Delete"}

                        </Button>
                        <Button size='lg' disabled={exporting} variant="outline-dark" onClick={() => handleExport()} style={{ marginRight: 16 }}>
                          <FontAwesomeIcon icon={light('file-export')} size={32} style={{ paddingRight: 4 }} />
                          {exporting ? "Exporting..." : "Export"}
                        </Button>
                      </div>
                      <Form>

                        <Form.Group className="mt-3" controlId="formBasicEmail">
                          <Form.Label>Privacy</Form.Label>

                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Public and private"
                            size={'lg'}
                          />
                        </Form.Group>
                      </Form>
                      <div style={{ paddingTop: 16 }}>
                        <Form>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Tags</Form.Label>
                            <ReactTags
                              tags={selectedTags}
                              suggestions={tags}
                              tagComponent={TagComponent}
                              onDelete={onTagsDelete}
                              onAddition={onTagsAddition}
                              placeholderText="Add to tags"

                            />
                            {/* <Form.Text className="text-muted">
                            {tagsToDelete.length == 0 && tagsToAdd.length == 0 && 
                              <span>
                              Search for tags or add new
                              </span>
}
{tagsToDelete.length > 0  && 
                              <span style={{paddingTop: 4}}>
                              Remove &nbsp;
                              {tagsToDelete.map((tag, index) => {
                                return(
                                  <TagComponent tag={tag} />
                                )
                              } )}
                              </span>
}
{tagsToAdd.length > 0  && 
                              <span style={{paddingTop: 4}}>
                              Add &nbsp;
                              {tagsToAdd.map((tag, index) => {
                                return(
                                  <TagComponent tag={tag} />
                                )
                              } )}
                              </span>
}
                            </Form.Text> */}

                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Groups</Form.Label>
                            <ReactTags
                              tags={selectedGroups}
                              suggestions={groups}
                              tagComponent={GroupComponent}
                              onDelete={onGroupsDelete}
                              onAddition={onGroupsAddition}
                              placeholderText="Add to groups"
                            />
                            <Form.Text className="text-muted">
                              Search for groups or add new
                            </Form.Text>

                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Collection</Form.Label>
                            <ReactTags
                              tags={selectedCollections}
                              suggestions={collections}
                              tagComponent={CollectionComponent}
                              onDelete={onCollectionsDelete}
                              onAddition={onCollectionsAddition}
                              placeholderText="Add to collections"
                            />
                            <Form.Text className="text-muted">
                              Search for collections or add new
                            </Form.Text>

                          </Form.Group>
                        </Form>
                      </div>
                    </div>

                  }


                </div>


              </Col>

            </Row>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => onClose()}>
            Cancel
          </Button>

          <Button variant="primary" onClick={() => handleSubmit()} disabled={saving}>
            {saving ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>


        <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)} centered >
          <Modal.Header closeButton>
            <Modal.Title>Delete {selectedPins.length} {selectedPins.length == 1 ? "pin" : "pins"}?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete {selectedPins.length == 1 ? "this" : "these"} {selectedPins.length == 1 ? "pin" : "pins"}?  This cannot be undone.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => handleDelete()} disabled={deleting}>
              {deleting ? "Deleting..." : "Delete"}
            </Button>

          </Modal.Footer>
        </Modal>

      </Modal>

    </>
  );
}