import '../App.css';
import { Map, Marker } from 'react-mapkit'
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'
import { best } from 'wcag-color'
import Form from 'react-bootstrap/Form';
import { getDistance, convertDistance } from 'geolib';
import { couldStartTrivia } from 'typescript';
import { API_URL } from '../vars'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)



export function PinItem({ pin, onPress, pinCard, pinTags, selectable, checked, last }) {


  const { pins, tags, relationships, location, miles } = React.useContext(PinDropContext)
  // console.log(pin)
  // console.log(tagMappings)

  // let pt = tags.filter(tag => tagMappings.filter(item => item.pin_id == pin.id).map(t => t.tag_id).includes(pin.id))
  let tms = relationships.filter(item => item.pin_id == pin.id).map(t => t.tag_id)

  const { jwt } = React.useContext(PinDropContext)

  const [distance, setDistance] = useState(0)

  React.useEffect(() => {
    if (pin.lat && pin.lng && location) {
      if (miles) {
        Math.round(convertDistance(
          getDistance(location, {
            latitude: pin.lat,
            longitude: pin.lng,
          })
          , "mi"))
      }
      if (!miles) {
        setDistance(Math.round(getDistance(location, {
          latitude: pin.lat,
          longitude: pin.lng,
        }) / 1000))
      }

    }
  }, [location, miles])


  return (
    <div className={pinCard ? "d-flex align-items-center" : "d-flex align-items-center border-bottom"} key={pin.id} onClick={(pin) => onPress(pin)}
      style={{ paddingBottom: 8, paddingTop: 8, cursor: "pointer", marginRight: 16 }}>
      <div class="p-2">


        {!pinCard &&
          <div>
            {pin.heroImage && pin.heroImage.length > 0 && !pin.orgID &&
              <img src={`${API_URL}/myMedia?file=${pin.id}/${pin.heroImage}&token=${jwt}`} style={{ width: 64, height: 64, marginRight: 8, borderRadius: 8 }} />
            }
            {pin.heroImage && pin.heroImage.length > 0 && pin.orgID &&
              <img src={`https://pindropbackup.blob.core.windows.net/${pin.orgID}/${pin.heroImage}`} style={{ width: 64, height: 64, marginRight: 8, borderRadius: 8 }} />
            }
            {(!pin.heroImage || pin.heroImage.length == 0) &&
              <img src={`/images/NoPhoto.png`} style={{ width: 64, height: 64, marginRight: 8, borderRadius: 8 }} />
            }
          </div>
        }
      </div>
      <div className={"d-flex flex-column align-center w-100"} >
        <span style={{ fontWeight: "600", fontSize: 17, color: "#3A3E44", lineHeight: 1, paddingBottom: 2 }}>
          {pin.name}
        </span>
        <span style={{ paddingTop: 0, marginTop: 0, paddingBottom: 0, marginBottom: 0, lineHeight: 1, }}>
          {location && !miles &&
            <span style={{ fontSize: 13, color: "#3A3E44", lineHeight: 1, paddingBottom: 2, paddingRight: 4 }}>
              {" "}{distance} km away &middot;
            </span>
          }
          {location && miles &&
            <span style={{ fontSize: 13, color: "#3A3E44", lineHeight: 1, paddingBottom: 2, paddingRight: 4 }}>
              {" "}{distance} {distance == 1 ? "mile" : "miles"} away &middot;
            </span>
          }
          <span style={{ fontSize: 13, color: "#3A3E44", lineHeight: 1, paddingBottom: 2 }}>
            {dayjs(pin.date_dropped).format('D MMM YYYY')}
          </span>
        </span>
        <span className="tagListPinCard d-flex w-100" style={{ paddingTop: 2, overflowX: pinCard ? "none" : "auto", whiteSpace: pinCard ? "wrap" : "nowrap" }}>

          {!pinTags && pin.hasOwnProperty("tag_ids") && tags.filter(t => pin.tag_ids.includes(t.id)).map((t, index) => {
            return (
              <span key={t.id} style={{ color: best('#ffffff', '#3A3E44', t.color), fontSize: 13, marginRight: 4, backgroundColor: t.color, paddingRight: 8, paddingLeft: 8, paddingTop: 0, paddingBottom: 0, borderRadius: 4 }}>{t.name.toUpperCase()}</span>
            )
          })}

          {pinTags && pinTags.map((t, index) => {
            return (
              <span key={t.id} style={{ color: best('#ffffff', '#3A3E44', t.color), fontSize: 13, marginRight: 4, backgroundColor: t.color, paddingRight: 8, paddingLeft: 8, paddingTop: 0, paddingBottom: 0, borderRadius: 4 }}>{t.name.toUpperCase()}</span>
            )
          })}


        </span>

      </div>


      {selectable &&
        <div class="p-2" >
          <Form.Check
            type={"checkbox"}
            id={pin.id}
            checked={checked}
          />

        </div>
      }
    </div>
  );
}