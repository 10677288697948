import '../App.css';
import Modal from 'react-bootstrap/Modal';

import React, { useEffect, useState, Fragment } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'
import { SketchPicker } from 'react-color';

import {
  Button, FloatingLabel, Row, Col, InputGroup
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Icon from "../Icon";
// import { parse, stringify } from 'yaml'
import IconsFile from '../icon-categories.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, light } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { best } from 'wcag-color'
import uuid from 'react-uuid'
import { API_URL } from '../vars'



var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function NewTag({ map, mapkit, visible, onClose, tag }) {


  function getTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');

    return titleCase;
  }


  const { jwt, pins, tags, relationships, setTags } = React.useContext(PinDropContext)
  const [show, setShow] = useState(false);
  const filterBy = () => true;
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchHandle, setSearchHandle] = useState(null)
  const [searchResults, setSearchResults] = useState(null)
  const [showError, setShowError] = useState(false)
  const [query, setQuery] = useState("")
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState("")
  const [color, setColor] = useState({ "hex": `#${Math.floor(Math.random() * 16777215).toString(16)}` })
  const [faIconsAll, setFaIconsAll] = useState({})
  const [faIcons, setFaIcons] = useState({})
  const [tagIcon, setTagIcon] = useState(null)

  const [iconQuery, setSetIconQuery] = useState("")
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const handleDelete = (e) => {

    setDeleting(true)
    fetch(`${API_URL}/deleteTag`, {
      method: 'POST',
      body: JSON.stringify({
        "token": jwt,
        "tagID": tag.id,
        "deletePins": false
      })
    }).then(res => res.json()).then(data => {


      setTags(tags.filter(t => t.id !== tag.id))
      onClose(true)

    }).finally(() => {
      setDeleting(false)
    }).catch(err => {
      console.log(err)
    })


  }



  React.useEffect(() => {
    if (IconsFile && visible) {
      setFaIconsAll(IconsFile)
      setFaIcons(IconsFile)
    }
  }, [IconsFile, visible])

  React.useEffect(() => {
    if (faIconsAll && iconQuery.length > 0) {
      let filtered = {}

      Object.entries(faIconsAll).map(([key, value]) => {
        {
          let qIcons = []
          value.icons.map((icon) => {
            if (icon.toLowerCase().includes(iconQuery.toLowerCase())) {
              qIcons.push(icon)
            }
          }
          )
          filtered[key] = { "icons": qIcons, "label": value.label }
        }
      }
      )

      setFaIcons(filtered)
    }

    if (iconQuery.length == 0) {
      setFaIcons(faIconsAll)
    }


  }, [faIconsAll, iconQuery])


  const handleSubmit = (e) => {

    const tag_id = tag ? tag.id : uuid()

    setSaving(true)
    fetch(`${API_URL}/saveWebData`, {
      method: 'POST',
      body: JSON.stringify({
        token: jwt,
        type: "tag",
        entity: {
          "id": tag_id,
          "name": name,
          "color": color.hex,
          "icon": tagIcon,

        },
      })
    }).then(res => res.json()).then(data => {
      console.log(data)

      if (tag && tags.find(tag => tag.id == tag_id)) {
        let tmpTags = tags.filter(tag => tag.id != tag_id)
        tmpTags.push({
          "id": tag_id,
          "name": name,
          "color": color.hex,
          "icon": tagIcon,
        })
        setTags(tmpTags)
      } else {
        setTags([...tags, {
          "id": tag_id,
          "name": name,
          "color": color.hex,
          "icon": tagIcon,
        }])
      }
      onClose({
        "id": tag_id,
        "name": name,
        "color": color.hex,
        "icon": tagIcon,
      })


    }).finally(() => {
      setSaving(false)
    }).catch(err => {
      console.log(err)
      setShowError(true)
      onClose()
    })


  }


  useEffect(() => {
    if (visible && tag) {
      setName(tag.name)
      setColor({ hex: tag.color })
      setTagIcon(tag.icon ? tag.icon : null)
    }
  }, [visible, tag]);


  useEffect(() => {
    if (map && mapkit) {
      var search = new mapkit.Search({ region: map.region });
      setSearchHandle(search)
    }
  }, [map, mapkit]);

  return (
    <>


      <Modal show={visible} fullscreen={false} onHide={() => onClose()} centered
              style={{filter: confirmDelete ? "brightness(50%)" : "brightness(100%)"}}

        dialogClassName="modal-70w">
        <Modal.Header closeButton>
          <Modal.Title style={{ width: "100%", justifyItems: "center" }}>
            {tag ? "Edit tag" : "Create Tag"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className="mb-3"
              >
                <Form.Control autoFocus placeholder="Give this a name" className='formInput' onChange={(e) => setName(e.target.value)} value={name} />
              </FloatingLabel>
            </Form.Group>
            <Row>
              <Col lg={11}>

                <InputGroup className="mb-3">
                  <Form.Control
                    autoFocus placeholder="Color"
                    className='formInput'
                    onChange={(e) => setName(e.target.value)} value={color.hex ? color.hex.toUpperCase() : ""}
                    style={{ borderRightWidth: 0 }}
                  />
                  <InputGroup.Text style={{ backgroundColor: "white", borderLeftWidth: 0 }}>

                    <div >
                      <div style={{
                        // padding: '5px',
                        background: '#fff',
                        borderRadius: '1px',
                        // boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                      }} onClick={() => setDisplayColorPicker(true)}>
                        <div style={{
                          marginTop: 4,
                          width: '88px',
                          height: '40px',
                          borderRadius: '4px',
                          background: color.hex,
                        }} />
                      </div>
                      {displayColorPicker &&
                        <div style={{
                          position: 'absolute',
                          zIndex: '2',
                        }}>
                          <div style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                          }} onClick={() => setDisplayColorPicker(false)} />
                          <SketchPicker color={color} onChange={(color) => setColor(color)} />
                        </div>
                      }

                    </div>
                  </InputGroup.Text>

                </InputGroup>


              </Col>
              <Col className="align-middle" lg={1} style={{ marginTop: -16, marginLeft: -16 }}>
                <div class="d-flex flex-column h-100 justify-content-center align-items-center" >
                  {tagIcon &&
                    <div style={{ position: "relative" }}>

                      <Icon icon="MapIconsClear" size={40} color={color.hex} />
                      <span class="d-flex" style={{ position: "absolute", "top": 0, "left": 0, height: 40, width: 40, alignItems: "center", justifyContent: "center" }}>
                        <i class={`fa-solid fa-${tagIcon}`} style={{ color: best('#ffffff', '#3A3E44', color.hex) }} ></i>
                      </span>
                    </div>

                  }
                  {!tagIcon &&
                    <Icon icon="MapIconsWeb" size={40} color={color.hex} />
                  }
                </div>

              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control autoFocus placeholder="Search icons" className='formInput' onChange={(e) => setSetIconQuery(e.target.value)} />
                  </Form.Group>

                  <div style={{ overflowY: "auto", maxHeight: 300 }}>
                    {
                      Object.entries(faIcons).map(([key, value]) => {
                        if (value.icons.length > 0) {
                          return (
                            <div

                            >
                              <Form.Label>{value.label}</Form.Label>
                              <div className="d-flex" style={{ flexWrap: "wrap" }}
                              >

                                {value.icons.map((icon) => {
                                  return (
                                    <OverlayTrigger
                                      key={icon}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-${icon}`}>
                                          {getTitleCase(icon.replace("-", " "))}
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        onClick={() => {
                                          setTagIcon(icon)

                                        }
                                        }
                                        class="d-flex align-items-center justify-content-center"
                                        style={{ backgroundColor: "rgba(0, 0, 0, 0.04)", borderRadius: 50, width: 50, height: 50, margin: 10 }}
                                      >
                                        <i class={`fa-solid fa-${icon}`} style={{ color: "#3A3E44", alignSelf: "center" }}></i>
                                      </span>
                                    </OverlayTrigger>

                                  )
                                }
                                )}
                              </div>
                            </div>

                          )
                        }
                      })
                    }
                  </div>


                </Form.Group>

              </Col>
            </Row>

            <Modal.Footer>
            {tag &&
          <Button variant="danger" onClick={() => setConfirmDelete(true)}>
            Delete
          </Button>
}

              <Button variant="outline-dark" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button variant="primary" disabled={saving} onClick={() => handleSubmit()}>
                {saving ? "Saving..." : "Save"}
              </Button>
            </Modal.Footer>


          </Form>


        </Modal.Body>
        <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete tag?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to delete just the Tag, or the Tag and its Pins?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => handleDelete()} disabled={deleting}>
           { deleting ? "Deleting..." : "The tag"}
          </Button>
          <Button variant="danger" onClick={() => handleDelete()} disabled={deleting}>
           { deleting ? "Deleting..." : "The tag and its pins"}
          </Button>

        </Modal.Footer>
      </Modal>

      </Modal>

    </>
  );
}