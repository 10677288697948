import '../App.css';
import { Map, Marker } from 'react-mapkit'
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import Icon from "../Icon";
import { PinDropContext } from '../Context'
import { PinItem } from './PinItem';
import { Pin } from './Pin';
import {
  Button, Row, Col, Container, Card
} from 'react-bootstrap';
import BackdropFilter from "react-backdrop-filter";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { best } from 'wcag-color'
import { NewCollection } from '../components/NewCollection';
import { AddToCollection } from '../components/AddToCollection';

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function Collection({ collection, data, onClose, map, mapkit }) {

  const { jwt, location } = React.useContext(PinDropContext)

  const [loadingCollection, setLoadingCollection] = useState(false)
  const [collectionPins, setCollectionPins] = useState([])
  const [collectionTags, setCollectionTags] = useState([])
  const [selectedPin, setSelectedPin] = useState(null)
  const [markers, setMarkers] = useState([]);
  const [selectedPinID, setSelectedPinID] = useState(null);
  const [editCollection, setEditCollection] = useState(false);
  const [addToCollection, setAddToCollection] = useState(false);
  const [eVSet, setEVSet] = useState(false);

  useEffect(() => {
    if (selectedPinID) {
      setSelectedPin(null)
      setSelectedPin(collectionPins.find(pin => pin.id === selectedPinID))
    }
  }, [selectedPinID, collectionPins])

  useEffect(() => {
    if (map && selectedPin) {
      var newCenter = new mapkit.Coordinate(selectedPin.lat, selectedPin.lng);
      var span = new mapkit.CoordinateSpan(.01);
      var region = new mapkit.CoordinateRegion(newCenter, span);
      map.setRegionAnimated(region)
      if (selectedPin) {
        let ann = map.annotations.find(a => a.data === selectedPin.id)
        ann.selected = true
      } else {
        map.annotations.forEach(a => a.selected = false)
      }

    }

  }, [selectedPin, map])

  useEffect(() => {

    if (map && mapkit) {

      map.showsUserLocation = true


      if (collectionPins.length > 0 && collectionTags.length > 0) {
        const ms = []
        map.removeAnnotations(map.annotations)

        setMarkers([])

        collectionPins.map(pin => {
          try {
            var newCoord = new mapkit.Coordinate(pin.lat, pin.lng);
            var newMarker = new mapkit.MarkerAnnotation(newCoord);
            newMarker.title = pin.title;
            newMarker.data = pin.id
            newMarker.color = collectionTags.find(t => pin.tags.includes(t.id)) ? collectionTags.find(t => pin.tags.includes(t.id)).color : "black"

            newMarker.glyphColor = best('#ffffff', '#3A3E44', '#000000')
            newMarker.glyphImage = { 1: "/Icon/MapPinLight.png", 2: "/Icon/MapPinLight@2x.png", 3: "/Icon/MapPinLight@3x.png" }
            newMarker.clusteringIdentifier = "me"

            ms.push(newMarker)
            map.addAnnotation(newMarker)
          } catch (e) {
            console.log(e)
          }
        })

        map.showItems(ms, {
          animate: true,
          padding: new mapkit.Padding(60, 25, 60, 25)
        })

        setMarkers(ms)



      }


      if (!eVSet) {
        map.addEventListener("single-tap", function configurationChanged(ev) {
          setSelectedPinID(null)
          setSelectedPin(null)
        });

        map.addEventListener("select", function configurationChanged(ev) {
          setSelectedPinID(ev.annotation.data)
        });
      }
      setEVSet(true)

    }
  }, [map, mapkit, location, collectionPins, collectionTags]);

  React.useEffect(() => {

    if (jwt) {
      setLoadingCollection(true)

      fetch("https://apiv2.pindrop.it/syncCollection", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "token": jwt,
          "orgID": collection.id,
        })
      })

        .then(res => res.json()).then(res => {
          setCollectionPins(res.filter(p => p.type == "pin"))
          setCollectionTags(res.filter(t => t.type == "tag"))
        }
        )
        .finally(() => {
          setLoadingCollection(false)
        })

    }
  }, [collection])



  return (
    <div style={{ padding: 16 }} >
      <Card style={{ borderRadius: 12, width: 380, height: 250, padding: 0, marginRight: 16, marginBottom: 8 }}
        onClick={() => console.log("close")}>
        <Card.Img style={{
          borderRadius: 12, filter: "brightness(0.6)",
          objectFit: "cover"
        }} height={250} width={380}
          src={(collection.backgroundPhoto && collection.backgroundPhoto.length > 0) ? `https://pindropbackup.blob.core.windows.net/${collection.id}/${collection.backgroundPhoto}` : `/images/NoPhoto.png`} />
        <Card.ImgOverlay style={{}}>
          <a href='#' onClick={() => onClose()} style={{ position: "absolute", top: "12px", right: "0px", padding: "10px" }}>
            <Icon icon="CollapseRight" size={40} color={"white"} style={{ position: "absolute", top: 8, right: 20 }} />
          </a>
          <div style={{ flexDirection: "column", justifyContent: "flex-end" }} className='w-100 h-100 d-flex' >
            <div className='title' style={{ color: 'white' }}>
              {collection.name}
            </div>
            <div className='subTitle' style={{ color: 'white' }}>
              Something
            </div>

            <div className="w-100 d-flex" style={{ marginTop: 8 }}>
              {collection.isOwner &&
                <Col style={{ marginRight: 16 }}>
                  <Button onClick={() => setEditCollection(collection)} className="w-100"
                    style={{
                      backgroundColor: "rgba(0,0,0,0.4)",
                      borderWidth: 0

                    }}>

                    <Icon icon="Edit" size={40} color={'white'} />
                  </Button>
                </Col>
              }
              <Col>
                <Button onClick={() => setAddToCollection(collection)} className="w-100"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.4)",
                    borderWidth: 0

                  }}>
                  <Icon icon="RoundPlus" size={40} color={'white'} />
                </Button>
              </Col>
            </div>
          </div>

        </Card.ImgOverlay>
      </Card>



      {editCollection &&
        <NewCollection show={editCollection}
          onClose={(deleted) => {
            setEditCollection(false);
            if (deleted) {
              onClose();
            }

          }}
          visible={editCollection}
          collection={collection}
          collectionSelectedPins={collectionPins}
          collectionSelectedTags={collectionTags}
        />
      }

      {addToCollection &&
        <AddToCollection show={addToCollection}
          onClose={(deleted) => {
            setAddToCollection(false);

          }}
          visible={addToCollection}
          collection={collection}
          collectionSelectedPins={collectionPins}
          collectionSelectedTags={collectionTags}
        />
      }

      <div class="pinListContainer">

        <div class="pinListTags">
          {collectionPins && collectionTags && collectionPins.map((pin, i) => {
            return (
              <PinItem
                pin={pin}
                pinTags={collectionTags.filter(t => pin.tags.includes(t.id))}
                onPress={() => {
                  setSelectedPin(pin)
                }}

              />
            )
          })
          }
        </div>
      </div>


      <Offcanvas show={selectedPin}
        placement={"end"}
        backdrop={false}
        onHide={() => {
          setSelectedPin(null);

          // setShowSearch(true);

        }}
        style={{ width: 410, padding: 0 }}>
        <Offcanvas.Body style={{ padding: 0 }}>
          {selectedPin &&
            <>

              <Pin pin={selectedPin} onClose={() => {
                setSelectedPin(null);

              }}
                pinTags={collectionTags.filter(t => selectedPin.tags.includes(t.id))}

              />
            </>
          }
        </Offcanvas.Body>
      </Offcanvas>

    </div>

  );
}