import '../App.css';
import Modal from 'react-bootstrap/Modal';

import React, { useEffect, useState, Fragment, useCallback } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'
import { PinItem } from '../components/PinItem';
import Icon from '../Icon';
import { PinChooser } from '../components/PinChooser';
import { useQueryClient } from 'react-query'


import uuid from 'react-uuid'
import { FileUploader } from "react-drag-drop-files";

import {
  Button, Row, Col, FloatingLabel, Alert
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { Map, Marker, useMap } from 'react-mapkit'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useDropzone } from 'react-dropzone';


import { API_URL } from '../vars'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function NewCollection({ visible, onClose, collection, collectionSelectedPins, collectionSelectedTags }) {

  const { jwt, pins, tags } = React.useContext(PinDropContext)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
    }
  });

  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [privacy, setPrivacy] = useState("Private")
  const [selectedTags, setSelectedTags] = useState([])
  const [showError, setShowError] = useState(false)
  const [localPhotos, setLocalPhotos] = useState([]);
  const [remotePhotos, setRemotePhotos] = useState([]);
  const [selectedPins, setSelectedPins] = useState([]);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(collection ? false : true);

  const [confirmDelete, setConfirmDelete] = useState(false)
  const queryClient = useQueryClient()

  const [allSelectedPins, setAllSelectedPins] = useState([]);


  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  React.useEffect(() => {

    let lpins = []
    selectedTags.map(tag => {
      lpins = lpins.concat(pins.filter(pin => pin.tag_ids.includes(tag.id)))
    }
    )

    lpins = lpins.concat(selectedPins)
    setAllSelectedPins(lpins)

  }, [selectedPins, selectedTags])


  useEffect(() => {
    // setResetPhoto(false)
    for (let i = 0; i < acceptedFiles.length; i++) {
      const f = acceptedFiles[i]
      setRemotePhotos([...remotePhotos, f])
      const file = URL.createObjectURL(f)
      console.log(file)
      setLocalPhotos([...localPhotos, file])
    }

  }, [acceptedFiles])

  const onPhotoChange = (e) => {
    setResetPhoto(false)

    if (e.length > 1) {
      console.log(e)
      const lFiles = []
      for (let i = 0; i < e.length; i++) {
        const f = e[i]
        setRemotePhotos([...remotePhotos, f])
        const file = URL.createObjectURL(f)
        console.log(file)
        setLocalPhotos([...localPhotos, file])
      }


    } else {

      setRemotePhotos([...remotePhotos, e])
      const file = URL.createObjectURL(e)
      console.log(file)
      setLocalPhotos([...localPhotos, file])
    }
  }

  const handleDelete = (e) => {

    setDeleting(true)
    fetch(`${API_URL}/deleteCollection`, {
      method: 'POST',
      body: JSON.stringify({
        "token": jwt,
        "orgID": collection.id,
      })
    }).then(res => res.json()).then(data => {

      queryClient.invalidateQueries(['collections']).then(() => {
        queryClient.refetchQueries(['collections'])
      })

      onClose(true)

    }).finally(() => {
      setDeleting(false)
    }).catch(err => {
      console.log(err)
    })


  }

  const handleSubmit = (e) => {



    setSaving(true)

    fetch(`${API_URL}/createOrg`, {
      method: 'POST',
      body: JSON.stringify({
        "title": name,
        "admins": [],
        "description": description,
        "token": jwt,
        "isCrew": false,
        "isOrg": false,
        "isCollection": true,
        "website": "",
        "domain": "",
        "logo": null,
        "address": "",
        "openDomain": false,
        "backgroundPhoto": remotePhotos.length > 0 ? remotePhotos[0].name : null,
        "orgParent": null,
        "orgID": collection ? collection.id : null,
        "backgroundImageUnsplash": null,
      })
    }).then(res => res.json()).then(data => {

      let bulkToOrg = []

      allSelectedPins.map(pin => {
        console.log(pin)
        tags.filter(tag => pin.tag_ids.includes(tag.id)).map(tag => {
          if (bulkToOrg.filter(b => b.id === tag.id).length === 0) {
            bulkToOrg.push({ "name": tag.name, "color": tag.color, "id": tag.id, "type": "tag" })
          }
        })

        bulkToOrg.push({
          "name": pin.name, "id": pin.id, "type": "pin", "description": pin.description,
          "lat": pin.lat, "lng": pin.lng, "heroImage": pin.heroImage,
          "pinTemplate": "1", "extended": [], "tag_ids": pin.tag_ids, "date_dropped": pin.date_dropped,
          "date_updated": pin.date_updated
        }
        )
      })

      selectedTags.map(tag => {
        if (bulkToOrg.filter(b => b.id === tag.id).length === 0) {

          bulkToOrg.push({ "name": tag.name, "color": tag.color, "id": tag.id, "type": "tag" })
        }
      })


      fetch(`${API_URL}/orgPost`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "token": jwt,
          "orgID": data.orgID,
          "entities": bulkToOrg,
        })
      })
        .catch((err) => {
          console.log("Error uploading pins", err)
        }).finally(() => {

          console.log("done uploading")
        })

      if (remotePhotos.length > 0) {
        fetch(`${API_URL}/orgMedia`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt,
            'org': data.orgID,
            'filename': remotePhotos[0].name
          },
          method: 'POST',
          body: remotePhotos[0]
        }).then(res => res.json()).then(data => {
          queryClient.invalidateQueries(['collections']).then(() => {
            queryClient.refetchQueries(['collections']).then(() => {
              setSaving(false)
              onClose()
            })

          })
        }).catch(err => {
          setSaving(false)
          setShowError(true)
          console.log(err)
        })


      } else {
        queryClient.invalidateQueries(['collections']).then(() => {
          queryClient.refetchQueries(['collections']).then(() => {
            setSaving(false)

            onClose()
          })


        })
      }

    }).finally(() => {

    }).catch(err => {
      console.log(err)
      setShowError(true)
      setSaving(false)

    })


  }


  React.useEffect(() => {
    if (collection && collectionSelectedPins && collectionSelectedTags) {
      setName(collection.name)
      setDescription(collection.description)
      setPrivacy(collection.privacy)
      setSelectedTags(collectionSelectedTags)
      setSelectedPins(collectionSelectedPins)
    }
  }, [collectionSelectedPins, collectionSelectedTags, collection])

  return (
    <>


      <Modal show={visible} onHide={() => onClose()}
        dialogClassName="modal-70w"
        animation={false}
        style={{ filter: confirmDelete ? "brightness(50%)" : "brightness(100%)" }}

      >
        <Modal.Header closeButton>
          {!collection &&
            <Modal.Title>
              Create Collection
            </Modal.Title>
          }
          {collection &&
            <Modal.Title>
              Edit Collection
            </Modal.Title>
          }
        </Modal.Header>
        <Modal.Body >
          {showError &&
            <Alert key={"danger"} variant={"danger"}>
              There was a problem saving your collection. Please try again later, or <Alert.Link href="mailto:hello@pindropapp.com" >contact us</Alert.Link> if this keeps happening.
            </Alert>
          }
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Name"
                    className="mb-3"
                  >
                    <Form.Control value={name} autoFocus placeholder="Give this a name" className='formInput' onChange={(e) => setName(e.target.value)} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Description"
                    className="mb-3"
                  >
                    <Form.Control
                      value={description}
                      as="textarea"
                      style={{ height: '100px' }}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>


                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Who can see this?</Form.Label>

                  <Form.Select size="lg" style={{ fontSize: 16, fontWeight: "400", height: 60 }}
                    onChange={(e) => setPrivacy(e.target.value)}
                    value={privacy}
                  >
                    <option>
                      Private
                    </option>
                    <option>
                      Public
                    </option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail" style={{ height: 348 }}>
                  <Form.Label>Cover Photo</Form.Label>
                  {/* <Tabs
                    defaultActiveKey="Upload"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                    // variant="pills"
                    style={{  padding: 8, marginBottom: 12 }}
                >
                    <Tab eventKey="Upload" title="Upload"> */}

                  <div class="d-flex container h-100">
                    {resetPhoto && localPhotos.length == 0 &&


                      <div {...getRootProps({ className: 'dropzone d-flex w-100 h-100 ' })}
                        style={{
                          borderStyle: "dashed", borderColor: "lightgray",
                          borderWidth: "1px", borderRadius: 10, flexDirection: "column",
                          alignItems: "center", justifyContent: "center",
                        }}>
                        <input {...getInputProps()} />
                        <span className="self-auto">Drag and drop cover image</span>
                        <p className="self-auto" style={{ fontSize: 13 }}>JPG, PNG</p>

                        <Button variant="outline-dark">
                          Upload
                        </Button>
                      </div>


                    }
                    <div class="d-flex justify-content-center">

                      {(collection && collection.backgroundPhoto.length > 0 && !resetPhoto && localPhotos.length == 0) &&

                        <div style={{ position: "relative", color: "red" }}>
                          <div style={{ position: "absolute", zIndex: 999, top: 16, right: 16 }}>
                            <Button style={{ fontWeight: "700", fontSize: 16, color: "#3A3E44" }} variant="light" onClick={() => { setLocalPhotos([]); setResetPhoto(true) }}>Edit image</Button>
                          </div>
                          <div style={{ position: "absolute", zIndex: 999, bottom: 16, left: 16 }}>
                            <div style={{ color: "white", fontWeight: "700", fontSize: 24 }}>{name}</div>
                            <div style={{ color: "white", fontWeight: "400", fontSize: 13, alignContent: "center" }}>
                              {privacy == "Private" &&
                                <Icon icon="Lock" size={20} color="white" />
                              }

                              Collection · {allSelectedPins.length} {allSelectedPins.length == 1 ? "pin" : "pins"}</div>
                          </div>
                          <img src={`https://pindropbackup.blob.core.windows.net/${collection.id}/${collection.backgroundPhoto}`}
                            class="img-fluid"
                            onClick={() => {
                              console.log("Resest photo")
                            }
                            }
                            style={{
                              objectFit: "cover",
                              borderRadius: 8, borderWidth: 1, borderColor: "gray", marginRight: 12, height: 347, width: "100%",
                              filter: "brightness(50%)"
                            }} />

                        </div>

                      }
                      {localPhotos && localPhotos.map((photo, index) => {
                        return (
                          <div style={{ position: "relative", color: "red" }}>
                            <div style={{ position: "absolute", zIndex: 999, top: 16, right: 16 }}>
                              <Button style={{ fontWeight: "700", fontSize: 16, color: "#3A3E44" }} variant="light" onClick={() => { setLocalPhotos([]); setResetPhoto(true) }}>Edit image</Button>
                            </div>
                            <div style={{ position: "absolute", zIndex: 999, bottom: 16, left: 16 }}>
                              <div style={{ color: "white", fontWeight: "700", fontSize: 24 }}>{name}</div>
                              <div style={{ color: "white", fontWeight: "400", fontSize: 13, alignContent: "center" }}>
                                {privacy == "Private" &&
                                  <Icon icon="Lock" size={20} color="white" />
                                }

                                Collection · {allSelectedPins.length} {allSelectedPins.length == 1 ? "pin" : "pins"}</div>
                            </div>
                            <img src={photo}
                              class="img-fluid"
                              key={index}
                              onClick={() => {
                                setLocalPhotos(localPhotos.filter((_, i) => i !== index))
                              }
                              }
                              style={{
                                objectFit: "cover",
                                borderRadius: 8, borderWidth: 1, borderColor: "gray", marginRight: 12, height: 347, width: "100%",
                                filter: "brightness(50%)"
                              }} />

                          </div>
                        )
                      }
                      )}
                    </div>
                  </div>
                  {/* </Tab>
                  <Tab eventKey="Unsplash" title="Unsplash">

<div class="d-flex align-items-center">
<UnsplashReact
  columns={4}
accessKey={"nQccaH-rGQ-7iCLEtyhz6onx-yfka9S880Vg1swYrO0"}
Uploader={withDefaultProps(Base64Uploader, { name: "event[logo]" })}
/>
</div>
  </Tab>

</Tabs> */}

                </Form.Group>

              </Col>
              <Col lg={6} >

                <PinChooser
                  setSelectedPins={(e) => setSelectedPins(e)}
                  setSelectedTags={(e) => setSelectedTags(e)}
                  selectedPins={selectedPins}
                  selectedTags={selectedTags}
                />

              </Col>
            </Row>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => onClose()}>
            Cancel
          </Button>
          {collection &&
            <Button variant="danger" onClick={() => setConfirmDelete(true)}>
              Delete
            </Button>
          }
          <Button variant="primary" onClick={() => handleSubmit()} disabled={saving}>
            {saving ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>

        <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete collection?</Modal.Title>
          </Modal.Header>
          <Modal.Body>This will delete the collection, its pins and its tags.  Are you sure?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => handleDelete()} disabled={deleting}>
              {deleting ? "Deleting..." : "Delete"}
            </Button>

          </Modal.Footer>
        </Modal>
      </Modal>

    </>
  );
}