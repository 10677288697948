import '../App.css';
import Modal from 'react-bootstrap/Modal';

import React, { useEffect, useState, Fragment } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'

import {
    InputGroup, Row, Col
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { PinItem } from './PinItem';
import { TagItem } from './TagItem';
import { useNavigate } from 'react-router-dom';

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function Search({ map, mapkit }) {

    const { jwt, pins, tags, relationships } = React.useContext(PinDropContext)
    const [show, setShow] = useState(false);
    const filterBy = () => true;
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchHandle, setSearchHandle] = useState(null)
    const [searchResults, setSearchResults] = useState(null)
    const [query, setQuery] = useState("")
    const [searchHistory, setSearchHistory] = useState([])
    let navigate = useNavigate();


    useEffect(() => {
        localStorage.getItem("searches") && setSearchHistory(JSON.parse(localStorage.getItem("searches")))
    }, [show])

    const saveSearch = (q) => {
        localStorage.getItem("searches") ? localStorage.setItem("searches", JSON.stringify([...JSON.parse(localStorage.getItem("searches")), q])) : localStorage.setItem("searches", JSON.stringify([q]))
    }

    const handleSearch = (query) => {
        setIsLoading(true);

        if (query.length == 0) {
            setSearchResults(null)

        }
        if (searchHandle) {


            searchHandle.search(query, function (error, data) {
                if (error) {
                    return;
                }
                var locations = data.places.map(function (place) {
                    return ({ "name": place.name, "subtitle": place.formattedAddress, lat: place.coordinate.latitude, lng: place.coordinate.longitude, "entity": "place" })
                });

                var searched_pins = pins.filter(p => p.name.toLowerCase().includes(query.toLowerCase())).map(pin => {
                    return (pin)
                })

                var searched_tags = tags.filter(t => t.name.toLowerCase().includes(query.toLowerCase())).map(tag => {
                    return (tag)
                })

                let results = searched_pins.concat(searched_tags).concat(locations)

                setSearchResults({ "places": locations, "pins": searched_pins, "tags": searched_tags })
                setIsLoading(false);
                // map.showItems(annotations);
            })


        }
    };


    useEffect(() => {
        if (map && mapkit) {
            var search = new mapkit.Search({ getsUserLocation: true });
            // var search = new mapkit.SearchAutocomplete({ getsUserLocation: true });
            setSearchHandle(search)
        }
    }, [map, mapkit]);

    return (
        <>

            <InputGroup id="mainSearch" className="mb-3">
                <Form.Control type="Search" placeholder="Search for something..."
                    // onChange={(e) => {setQuery(e.target.value); setShow(true)}}
                    onClick={(e) => { setQuery(e.target.value); setShow(true) }}
                    value={query}
                    // onFocus={() => setShow(true)}
                    style={{ borderRadius: 16, height: 64, fontSize: 16, fontWeight: "400", color: "rgba(58, 62, 68, 0.6);" }}
                />
            </InputGroup>

            <Modal show={show} fullscreen={true} onHide={() => setShow(false)} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ width: "100%", justifyItems: "center" }}>
                        <Form>
                            <Form.Group controlId="exampleForm.ControlInput1">

                                <Form.Control type="Search" placeholder="Search for something..."
                                    onChange={(e) => { setQuery(e.target.value); handleSearch(e.target.value) }}
                                    autoFocus
                                    value={query}
                                    
                                    style={{ borderRadius: 16, height: 64, fontSize: 16, fontWeight: "400", color: "rgba(58, 62, 68, 0.6);" }}
                                />

                            </Form.Group>
                        </Form>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!searchResults &&
                        <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                            <p className='title'>Find something</p>
                            <p className='secondaryText'>Search for: an address, zip code, what3words, pin, tag, place, crew, person or collection.</p>
                        </div>
                    }
                    {searchResults &&
                        <Row>
                            <Col lg={9} >
                                <Row>
                                    <Col>
                                        <p className='searchSectionTitle'>PINS</p>
                                        {searchResults.pins.map(pin => {
                                            return (
                                                <div className="searchResult" >
                                                    <PinItem
                                                        pin={pin}
                                                        onPress={() => {
                                                            saveSearch(query)
                                                            navigate(`/pins/${pin.id}`)
                                                            setShow(false)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                        }
                                        {searchResults.pins.length == 0 && <p className='p-1 secondaryText'>No pins found</p>}

                                        <p className='searchSectionTitle'>TAGS</p>
                                        {searchResults.tags.map(tag => {
                                            return (
                                                <div className="searchResult" >
                                                    <TagItem
                                                        tag={tag}
                                                        onPress={() => {
                                                            saveSearch(query)
                                                            navigate(`/tags/${tag.id}`)
                                                            setShow(false)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                        }

                                        {searchResults.tags.length == 0 && <p className='p-1 secondaryText'>No tags found</p>}




                                    </Col>
                                    <Col>
                                        <p className='searchSectionTitle'>PLACES</p>
                                        {searchResults.places.map(place => {
                                            return (
                                                <div className="searchResult"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        saveSearch(query)
                                                        navigate(`/pins/new?lat=${place.lat}&lng=${place.lng}&name=${place.name}`)
                                                        setShow(false)
                                                    }}

                                                >
                                                    <div className="placeResultTitle">{place.name}</div>
                                                    <div className="placeResultSubtitle">{place.subtitle}</div>
                                                </div>
                                            )
                                        })
                                        }
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                    </Col>
                                    <Col>

                                    </Col>

                                </Row>
                            </Col>
                            <Col>
                                <p>Recent searches</p>
                                {searchHistory.map(q => {
                                    return (
                                        <div className="searchResult" >
                                            <div style={{ cursor: "pointer" }} className="placeResultTitle" onClick={() => { setQuery(q); handleSearch(q) }}>{q}</div>
                                            {/* <div className="placeResultSubtitle">{place.subtitle}</div> */}
                                        </div>
                                    )
                                })
                                }

                            </Col>
                        </Row>


                    }
                </Modal.Body>
            </Modal>

        </>
    );
}