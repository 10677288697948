import React, { useEffect, useState } from 'react';


import { Outlet, useNavigate, useLocation } from "react-router-dom";

import jwt_decode from "jwt-decode";
import { API_URL } from './vars';
import { useParams } from 'react-router-dom';


import './App.css';

import Modal from 'react-bootstrap/Modal';
import {
  NavLink
} from "react-router-dom";
import { PinDropContext } from './Context';
import { Settings } from './components/Settings';

var Spinner = require('react-spinkit');
var georbush = require('georbush');


export default function App({ msalInstance }) {


  const navigate = useNavigate()
  const params = useParams();

  const [org, setOrg] = useState(null);

  const [booleanState, setBooleanState] = useState(false);
  const [uuidAuth, setUUIDAuth] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [pinsLoaded, setPinsLoaded] = useState(false);
  const { profilePhoto, setRefreshPins, setProfilePhoto, refreshPins, loading, location, setLocation, setLoading, loadingPins, setLoadingPins, setPins, setMedia, logout, migrating, setMigrating, pins, tags, relationships, rBush, setTags, setRelationships, setJWT, jwt, subscription, setSubscription } = React.useContext(PinDropContext)

  const currentRoute = useLocation();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


  let query = useQuery();



  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
      })
    } else {
      console.log("Geolocation is not supported by this browser.")
    }

  }, [])
  function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  useEffect(() => {
    console.log("Adding pins to rBush")

    if (!pinsLoaded && jwt && !migrating) {
      setLoadingPins(true)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: jwt })
      };
      fetch(`${API_URL}/webData`, requestOptions)
        .then(response => response.json())
        .then(data => {
          // console.log(data)

          rBush.clear()
          for (let index = 0; index < data["pins"].length; index++) {
            const pin = data["pins"][index];
            let pin_tags = data["relationships"].filter(item => item.pin_id == pin.id).map(tags => tags.tag_id)
            if (pin.lat && pin.lng && isFloat(pin.lat) && isFloat(pin.lng)) {

              rBush.insert({
                minX: pin.lat, maxX: pin.lat, minY: pin.lng, maxY: pin.lng, id: pin.id,
                name: pin.name, lat: pin.lat, lng: pin.lng, latitude: pin.lat, longitude: pin.lng, heroImage: pin.heroImage, tag_ids: pin_tags,
                date_dropped: pin.date_dropped, date_updated: pin.date_updated, isSecret: pin.isSecret,
                favourite: pin.favourite, description: pin.description ? pin.description : "",
                orgId: pin.orgId, isOrg: pin.isOrg == 1 ? true : false, isCollection: pin.isCollection == 1 ? true : false,
                isCrew: pin.isCrew == 1 ? true : false, isOwner: pin.isOwner == 1 ? true : false, canEdit: pin.canEdit == 1 ? true : false
              })
            }
          }

          // if (location) {
          //   console.log("Location set!", location, rBush.all().length)
          //   setPins(georbush.around(rBush, location.latitude, location.longitude))

          // } else {
          //   console.log("Location not set!", location)

          //   setPins(rBush.all())
          // }

          setTags(data["tags"])
          setRelationships(data["relationships"])
          setMedia(data["media"])
          setRefreshPins(Math.random())

        }).finally(() => {
          setLoadingPins(false)
          setPinsLoaded(true)
        })

    }
  }, [loading, jwt, pins])

  useEffect(() => {

    // const params = useParams();

    if (jwt) {
      console.log("Redirecting to Pins...")
      if (params && params.id && params.id == "new") {
        return
      }

      navigate("/pins")


    }

  }, [jwt])

  useEffect(() => {
    if (location && refreshPins) {
      console.log("Location set!")
      console.log("Setting Pins!")
      setPins(georbush.around(rBush, location.latitude, location.longitude))

    } else {
      console.log("Location not set!")

      setPins(rBush.all())
    }

  }, [location, refreshPins])

  useEffect(() => {


    if (jwt) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: jwt })
      };
      fetch(`${API_URL}/me`, requestOptions)
        .then(response => response.json())
        .then(data => {
          setProfilePhoto(data.profilePhoto)
        })
    }


  }, [jwt])

  useEffect(() => {


    if (jwt && org && subscription == "Pro") {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: jwt, orgID: org.id, cacheTimings: [] })
      };
      fetch(`${API_URL}/syncOrg`, requestOptions)
        .then(response => response.json())
        .then(data => {
          setPins(data.filter(ele => ele.type === "pin"))
          setTags(data.filter(ele => ele.type === "tag"))

        })


    }



    if (jwt && (subscription == "Lite" || subscription == "Plus" || subscription == "Pro")) {
      console.log("Fetching data")
      setMigrating(true)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: jwt })
      };
      fetch(`${API_URL}/migrateLive`, requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)

        }).finally(() => {
          setMigrating(false)
        })


    }

  }, [jwt, org, subscription]);


  useEffect(() => {
    if (subscription === "Base" && currentRoute.pathname !== "/upgrade" && currentRoute.pathname !== "/pins/new") {
      window.top.location.href = "/upgrade";
    }
  }, [subscription])

  useEffect(() => {


    console.log("Checking for auth", migrating, loading, loadingPins)
    const sJwt = localStorage.getItem("jwt")

    if (sJwt) {
      const payload = jwt_decode(sJwt)
      setSubscription(payload.subscription)

    }


    if (sJwt && !migrating && !loading) {
      setBooleanState(false)
      window.top.location.href = "/pins";
    }


    if (!sJwt) {
      setBooleanState(true)
      window.location.href = "/home";

    }

  }, [migrating, loading, loadingPins]);


  // useEffect(() => {
  //   sessionStorage.getItem("jwt").then((jwt) => {

  //     if (jwt) {
  //       if (jwt) {
  //         setBooleanState(false)
  //         // navigate('/pins')
  //       }
  //     }

  //   if (!jwt) {
  //     setBooleanState(true)
  //     window.location.href = "/home";

  //   }

  //   })


  // }, [jwt]);






  function handleLogout() {
    console.log("Removing session")
    setJWT(null)
    localStorage.removeItem("jwt")
    setBooleanState(true)
    window.location.href = "/home";
  }


  if (!jwt) {
    return (

      <div class="wrapper" style={{ backgroundColor: "white" }}>

        <Modal show={true} fullscreen={true}>
          <Modal.Body>
            <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
              <Spinner name="double-bounce" color="#54AD95" style={{ width: 60, height: 60 }} />
              <p className='secondaryText' style={{ paddingTop: 16 }}>Loading...</p>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }

  if (jwt) {
    return (


      <div class="wrapper" style={{ backgroundColor: "white" }}>

        <Modal show={migrating} fullscreen={true}>
          <Modal.Body>
            <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
              <Spinner name="double-bounce" color="#54AD95" style={{ width: 60, height: 60 }} />
              <p className='secondaryText' style={{ paddingTop: 16 }}>Unfolding the map...</p>
            </div>
          </Modal.Body>
        </Modal>

        <Settings visible={showSettings} onClose={() => setShowSettings(false)} />

        <div id="sideNav" class="d-flex flex-column flex-shrink-0" style={{ width: 104 }}>
          <div class="sidebar-header" style={{ padding: 0 }}>
            <img src="/images/icons/Logo.png" width={64} height={64} />
          </div>

          <a href="/" class="d-block p-0 link-dark text-decoration-none" title="Icons" data-bs-toggle="tooltip" data-bs-placement="right">
            <span class="visually-hidden">Icon-only</span>
          </a>
          {subscription !== "Base" &&
            <ul class="nav nav-pills nav-flush flex-column mb-auto text-center sideNavLinks" style={{ alignContent: "center" }}>
              <li class="nav-item">
                <NavLink to="/pins" style={{ padding: 0 }}>
                  <img src="/images/icons/Pins.png" width={60} height={60} />
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink to="/tags" style={{ padding: 0 }}>
                  <img src="/images/icons/Tags.png" width={60} height={60} />
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink to="/collections" style={{ padding: 0 }}>
                  <img src="/images/icons/Collections.png" width={60} height={60} />
                </NavLink>
              </li>
              <li class="nav-item nav-group">
                <NavLink to="/groups" style={{ padding: 0 }}>
                  <img src="/images/icons/Crew.png" width={60} height={60} />
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink to="/sync" style={{ padding: 0 }}>
                  <img src="/images/icons/Sync.png" width={60} height={60} />
                </NavLink>
              </li>

            </ul>
          }
          {subscription !== "Base" &&

            <div id="sideNavFooter">
              <ul>
                <li>
                  <a href="#" onClick={() => setShowSettings(true)} class="d-flex align-items-center justify-content-center link-dark text-decoration-none">
                    <img src="/images/icons/Settings.png" width={60} height={60} />
                  </a>
                </li>
                <li>
                  {profilePhoto &&
                    <a href="#" class="d-flex align-items-center justify-content-center link-dark text-decoration-none"
                      onClick={() => handleLogout()}>

                      <img src={`https://pindropbackup.blob.core.windows.net/img/${profilePhoto}`} alt="mdo" width="32" height="32" class="rounded-circle" />
                    </a>
                  }
                </li>
              </ul>
            </div>
          }
        </div>



        <div id="content">
          <Outlet />
        </div>
      </div>




    )
  }



};


