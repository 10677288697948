import '../App.css';
import {
  Col,
  Row,
  Card,
  Container
} from 'react-bootstrap';

import React, { useEffect, useState } from 'react';
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useNavigate } from 'react-router-dom';
import { PinDropContext } from '../Context'
import axios from "axios";

import { Map, Marker, useMap } from 'react-mapkit'
import { best } from 'wcag-color'
import { Search } from '../components/Search';
import { Group } from '../components/Group';
import { NewGroup } from '../components/NewGroup';
import Icon from '../Icon';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ShimmerText } from "react-shimmer-effects";
import { GroupItem } from '../components/GroupItem';
import { useQuery } from "react-query";


import dayjs from 'dayjs'
import { API_URL } from '../vars';
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default function Groups() {


  const [newGroup, setNewGroup] = useState(false);
  const [org, setOrg] = useState(null);
  const [addCollection, setAddCollection] = useState(false);

  const [groups, setGroups] = useState([]);
  const [orgPeople, setOrgPeople] = useState([]);
  const [collectionTemplates, setCollectionTemplates] = useState([]);
  const [group, setCollection] = useState(null);

  const [groupPins, setGroupPins] = useState([]);
  const [localLogo, setLocalLogo] = useState();
  const [backgroundPhoto, setBackgroundPhoto] = useState();
  const [showGroup, setShowGroup] = useState(false);
  const [saving, setSaving] = useState(false);
  const { map, mapProps, setRotation, mapkit } = useMap()


  const [remoteLogo, setRemoteLogo] = useState();
  const [remoteBackgroundPhoto, setRemoteBackgroundPhoto] = useState();


  const [collectionTitle, setCollectionTitle] = useState("");
  const [collectionMessage, setCollectionMessage] = useState("");
  const [updateList, setUpdateList] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  let navigate = useNavigate();



  const { loading, location, setLocation, setLoading, loadingPins, setLoadingPins, setPins, logout, migrating, setMigrating, pins, tags, relationships, rBush, setTags, setRelationships, setJWT, jwt, subscription, setSubscription } = React.useContext(PinDropContext)




  useEffect(() => {
    if (jwt && org) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: jwt, orgID: org.id })
      };
      fetch('https://apiv2.pindrop.it/orgUsers', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          setOrgPeople(data)
        });


    }
  }, [jwt, org]);


  function useGroups() {
    return useQuery(["crews"], async () => {
      const { data } = await axios.post(`${API_URL}/getOrg`, { token: jwt })
      return data;
    });
  }

  const { isLoading, isError, data, error, refetch } = useGroups()


  useEffect(() => {
    if (data) {
      setGroups(data.filter(g => g.isCrew));
      setLoadingGroups(false);
    }
  }, [data]);

  // useEffect(() => {
  //   if (jwt && org) {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ token: jwt, orgID: org.id })
  //     };
  //     fetch('https://apiv2.pindrop.it/orgCollections', requestOptions)
  //       .then(response => response.json())
  //       .then(data => {
  //         console.log("collections", data)
  //         setGroups(data)
  //       });


  //   }
  // }, [jwt, org, updateList]);





  const saveCollection = async () => {
    setSaving(true)
    var formData = new FormData();

    formData.append(remoteLogo.name, remoteLogo, remoteLogo.name)

    const logoUpload = await fetch(`https://apiv2.pindrop.it/orgMedia`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'multipart/form-data',
        "Authorization": jwt, "org": org.id,
        "filename": remoteLogo.name
      },
      body: formData
    })

    const logoUploadResponse = await logoUpload.json()
    console.log(logoUploadResponse)

    var formDataBG = new FormData();

    formDataBG.append(remoteBackgroundPhoto.name, remoteBackgroundPhoto, remoteBackgroundPhoto.name)

    const backgroundUpload = await fetch(`https://apiv2.pindrop.it/orgMedia`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'multipart/form-data',
        "Authorization": jwt, "org": org.id,
        "filename": remoteBackgroundPhoto.name
      },
      body: formDataBG
    })

    const backgroundUploadResponse = await backgroundUpload.json()
    console.log(backgroundUploadResponse)


    fetch("https://apiv2.pindrop.it/createOrg", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "title": collectionTitle,
        "website": null,
        "domain": null,
        "address": null,
        "admins": [],
        "token": jwt,
        "logo": remoteLogo.name,
        "openDomain": false,
        "isCrew": false,
        "fullSync": true,
        "showProfile": true,
        "isCollection": true,
        "isOrg": false,
        "orgParent": org.id,
        "backgroundPhoto": remoteBackgroundPhoto.name,
      })
    }).then(res => res.json()).then(res => {
      console.log(res)
      if (res.orgID) {
        console.log("Saved")
        setUpdateList(!updateList)
        setAddCollection(false)
      }

    }
    ).finally(() => {
      setSaving(false)
    })
  }


  const onLogoChange = (e) => {
    console.log(e.target.files[0])
    setRemoteLogo(e.target.files[0])
    const file = URL.createObjectURL(e.target.files[0])
    setLocalLogo(file)
  }

  const onBackgroundChange = (e) => {
    console.log(e.target.files[0])
    setRemoteBackgroundPhoto(e.target.files[0])
    const file = URL.createObjectURL(e.target.files[0])
    setBackgroundPhoto(file)
  }


  return (

    <Row className={"map"} style={{ width: "100%", height: "90%" }}>
      <Col >



        <Search map={map} mapkit={mapkit} />

        <Map tracksUserLocation center={[location ? location.latitude : 0, location ? location.longitude : 0]} {...mapProps} showsUserLocation>

          {false && groupPins.map((pin, i) => {
            if (pin.lat && pin.lng) {
              return (<Marker
                latitude={parseFloat(pin.lat)}
                longitude={parseFloat(pin.lng)}
                key={i}
                // color={tagMappings.find() }
                title={pin.name}
                // subtitle={pin.description}
                // select={(item) => alert(item)}
                data={pin.id}
                color={tags.find(t => pin.tag_ids.includes(t.id)) ? tags.find(t => pin.tag_ids.includes(t.id)).color : "black"}
                // color={ tags.find(item =>  pin.tag_ids.includes(item.id)) ?  tags.find(item =>  pin.tag_ids.includes(item.id)).color : "black" }
                // relationships.find(r => r.tag_id == t.id && r.pin_id == pin.id)) ? tags.find(t => relationships.find(r => r.tag_id == t.id && r.pin_id == pin.id)).color : "black"}
                glyphColor={best('#ffffff', '#3A3E44', '#000000')}
                glyphImage={{ 1: "/Icon/MapPinLight.png", 2: "/Icon/MapPinLight@2x.png", 3: "/Icon/MapPinLight@3x.png" }}
                clusteringIdentifier={"me"}

              />)
            }
          })}
        </Map>

      </Col>
      <div class="pinListContainer" >
        <div class="d-flex justify-content-between" style={{ paddingLeft: 8 }}>
          <div class=" d-flex justify-content-start title">
            Groups
          </div>
          {!loadingGroups &&
            <div class="d-flex justify-content-end" style={{ paddingRight: 0 }}>
              <a href="#" onClick={() => {
                setNewGroup(true)
              }} >
                <Icon icon="BigPlus" size={40} />
              </a>
            </div>
          }
        </div>

        <div class="pinList" >
          {loadingGroups &&
            <div style={{ paddingLeft: 10 }}>
              <div style={{ width: 380 }}>
                <ShimmerText line={5} gap={10} />
                <ShimmerText line={5} gap={10} />
                <ShimmerText line={5} gap={10} />
                <ShimmerText line={5} gap={10} />
                <ShimmerText line={5} gap={10} />
                <ShimmerText line={5} gap={10} />
                <ShimmerText line={5} gap={10} />
                <ShimmerText line={5} gap={10} />

              </div>
            </div>}


          {!loadingGroups &&
            <div style={{ paddingLeft: 10 }} className="w-100">
              {/* <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="home" title="My Collections">

      </Tab>
      <Tab eventKey="profile" title="Curated">

      </Tab>
    </Tabs> */}



              {groups.map((c, i) => {
                return (
                  <GroupItem group={c} onPress={() => setShowGroup(c)} />
                )
              })}
            </div>
          }
        </div>
      </div>

      <Offcanvas show={showGroup}
        placement={"end"}
        backdrop={false}
        onHide={() => {
          setShowGroup(false);


        }}
        style={{ width: 425, padding: 0 }}>
        <Offcanvas.Body style={{ padding: 0 }}>
          {showGroup &&
            <>
              <Group collection={showGroup} data={[]} onClose={() => setShowGroup(false)} map={map} mapkit={mapkit} />

            </>
          }
        </Offcanvas.Body>
      </Offcanvas>

      {/* <NewTag show={newTag} onClose={() => {
        setNewTag(false);
      }} visible={newTag}  /> */}





      {newGroup &&
        <NewGroup show={newGroup} onClose={() => {
          setNewGroup(false);
        }} visible={newGroup} newPin={newGroup} map={map} mapkit={mapkit} />
      }


    </Row>


  );
}