import '../App.css';
import Modal from 'react-bootstrap/Modal';

import React, { useEffect, useState, Fragment, useCallback } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'
import { PinItem } from '../components/PinItem';
import Icon from '../Icon';
import { PinChooser } from '../components/PinChooser';
import UnsplashReact, { Base64Uploader, withDefaultProps } from "unsplash-react"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useQueryClient } from 'react-query'


import uuid from 'react-uuid'
import { FileUploader } from "react-drag-drop-files";

import {
  Button, Row, Col, FloatingLabel, Alert
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { Map, Marker, useMap } from 'react-mapkit'
import 'react-bootstrap-typeahead/css/Typeahead.css';


import { API_URL } from '../vars'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function NewGroup({ visible, onClose, collection, collectionSelectedPins, collectionSelectedTags }) {

  const { jwt, pins, tags } = React.useContext(PinDropContext)

  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [privacy, setPrivacy] = useState("Private")
  const [selectedTags, setSelectedTags] = useState([])
  const [showError, setShowError] = useState(false)
  const [localPhotos, setLocalPhotos] = useState([]);
  const [remotePhotos, setRemotePhotos] = useState([]);
  const [selectedPins, setSelectedPins] = useState([]);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(collection ? false : true);

  const [confirmDelete, setConfirmDelete] = useState(false)
  const queryClient = useQueryClient()

  const [allSelectedPins, setAllSelectedPins] = useState([]);


  React.useEffect(() => {

    let lpins = []
    selectedTags.map(tag => {
      lpins = lpins.concat(pins.filter(pin => pin.tag_ids.includes(tag.id)))
    }
    )

    lpins = lpins.concat(selectedPins)
    setAllSelectedPins(lpins)

  }, [selectedPins, selectedTags])

  const onPhotoChange = (e) => {
    setResetPhoto(false)

    if (e.length > 1) {
      console.log(e)
      const lFiles = []
      for (let i = 0; i < e.length; i++) {
        const f = e[i]
        setRemotePhotos([...remotePhotos, f])
        const file = URL.createObjectURL(f)
        console.log(file)
        setLocalPhotos([...localPhotos, file])
      }


    } else {

      setRemotePhotos([...remotePhotos, e])
      const file = URL.createObjectURL(e)
      console.log(file)
      setLocalPhotos([...localPhotos, file])
    }
  }

  const handleDelete = (e) => {

    setDeleting(true)
    fetch(`${API_URL}/deleteCollection`, {
      method: 'POST',
      body: JSON.stringify({
        "token": jwt,
        "orgID": collection.id,
      })
    }).then(res => res.json()).then(data => {

      queryClient.invalidateQueries(['collections']).then(() => {
        queryClient.refetchQueries(['collections'])
      })

      onClose(true)

    }).finally(() => {
      setDeleting(false)
    }).catch(err => {
      console.log(err)
    })


  }

  const handleSubmit = (e) => {



    setSaving(true)

    console.log(JSON.stringify({
      "title": name,
      "admins": [],
      "description": description,
      "token": jwt,
      "isCrew": true,
      "isOrg": false,
      "isCollection": false,
      "website": "",
      "domain": "",
      "logo": null,
      "address": "",
      "openDomain": false,
      "backgroundPhoto": remotePhotos.length > 0 ? remotePhotos[0].name : null,
      "orgParent": null,
      "orgID": collection ? collection.id : null,
      "backgroundImageUnsplash": null,
    }))
    fetch(`${API_URL}/createOrg`, {
      method: 'POST',
      body: JSON.stringify({
        "title": name,
        "admins": [],
        "description": description,
        "token": jwt,
        "isCrew": true,
        "isOrg": false,
        "isCollection": false,
        "website": "",
        "domain": "",
        "logo": null,
        "address": "",
        "openDomain": false,
        "backgroundPhoto": remotePhotos.length > 0 ? remotePhotos[0].name : null,
        "orgParent": null,
        "orgID": collection ? collection.id : null,
        "backgroundImageUnsplash": null,
      })
    }).then(res => res.json()).then(data => {



      queryClient.invalidateQueries(['crews']).then(() => {
        queryClient.refetchQueries(['crews']).then(() => {
          setSaving(false)
          onClose()
        })


      })
    }).catch(err => {

      console.log(err)
      setSaving(false)
    }
    )
  }


  React.useEffect(() => {
    if (collection && collectionSelectedPins && collectionSelectedTags) {
      setName(collection.name)
      setDescription(collection.description)
      setPrivacy(collection.privacy)
      setSelectedTags(collectionSelectedTags)
      setSelectedPins(collectionSelectedPins)
    }
  }, [collectionSelectedPins, collectionSelectedTags, collection])

  return (
    <>


      <Modal show={visible} onHide={() => onClose()}
        dialogClassName="modal-70w"
        animation={false}
        centered
        style={{ filter: confirmDelete ? "brightness(50%)" : "brightness(100%)" }}

      >
        <Modal.Header closeButton>
          {!collection &&
            <Modal.Title>
              Create Group
            </Modal.Title>
          }
          {collection &&
            <Modal.Title>
              Edit Group
            </Modal.Title>
          }
        </Modal.Header>
        <Modal.Body >
          {showError &&
            <Alert key={"danger"} variant={"danger"}>
              There was a problem saving your collection. Please try again later, or <Alert.Link href="mailto:hello@pindropapp.com" >contact us</Alert.Link> if this keeps happening.
            </Alert>
          }
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Name"
                    className="mb-3"
                  >
                    <Form.Control value={name} autoFocus placeholder="Give this a name" className='formInput' onChange={(e) => setName(e.target.value)} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Description"
                    className="mb-3"
                  >
                    <Form.Control
                      value={description}
                      as="textarea"
                      style={{ height: '100px' }}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>


                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Who can see this?</Form.Label>

                  <Form.Select size="lg" style={{ fontSize: 16, fontWeight: "400", height: 60 }}
                    onChange={(e) => setPrivacy(e.target.value)}
                    value={privacy}
                  >
                    <option>
                      Private
                    </option>
                    <option>
                      Public
                    </option>
                  </Form.Select>
                </Form.Group>

              </Col>
              <Col lg={6} style={{ height: 200 }}>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Cover Photo</Form.Label>
                  {/* <Tabs
                    defaultActiveKey="Upload"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                    // variant="pills"
                    style={{  padding: 8, marginBottom: 12 }}
                >
                    <Tab eventKey="Upload" title="Upload"> */}

                  <div class="d-flex align-items-center">
                    {resetPhoto && localPhotos.length == 0 &&
                      <FileUploader handleChange={(e) => onPhotoChange(e)} name="file" types={["JPG", "PNG", "GIF"]}


                        children={
                          <div className="items-center p-4 m-4 text-center  w-100 h-100"
                            style={{ borderStyle: "dashed", borderColor: "lightgray", borderWidth: "1px", borderRadius: 10 }}>

                            <span className="self-auto">Drag and drop cover image</span>
                            <p className="self-auto" style={{ fontSize: 13 }}>JPG, PNG</p>

                            <Button variant="outline-dark">
                              Upload
                            </Button>
                          </div>
                        }
                      />
                    }
                    <div class="d-flex justify-content-center">

                      {localPhotos && localPhotos.map((photo, index) => {
                        return (
                          <div style={{ position: "relative", color: "red" }}>
                            <div style={{ position: "absolute", zIndex: 999, top: 16, right: 16 }}>
                              <Button style={{ fontWeight: "700", fontSize: 16, color: "#3A3E44" }} variant="light" onClick={() => { setLocalPhotos([]); setResetPhoto(true) }}>Edit image</Button>
                            </div>


                            <img src={photo}
                              class="img-fluid"
                              key={index}
                              onClick={() => {
                                setLocalPhotos(localPhotos.filter((_, i) => i !== index))
                              }
                              }
                              style={{
                                objectFit: "cover",
                                borderRadius: 8, borderWidth: 1, borderColor: "gray", marginRight: 12, height: 347, width: "100%",
                                filter: "brightness(50%)"
                              }} />

                          </div>
                        )
                      }
                      )}
                    </div>
                  </div>
                  {/* </Tab>
                  <Tab eventKey="Unsplash" title="Unsplash">

<div class="d-flex align-items-center">
<UnsplashReact
  columns={4}
accessKey={"nQccaH-rGQ-7iCLEtyhz6onx-yfka9S880Vg1swYrO0"}
Uploader={withDefaultProps(Base64Uploader, { name: "event[logo]" })}
/>
</div>
  </Tab>

</Tabs> */}

                </Form.Group>


              </Col>
            </Row>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => onClose()}>
            Cancel
          </Button>
          {collection &&
            <Button variant="danger" onClick={() => setConfirmDelete(true)}>
              Delete
            </Button>
          }
          <Button variant="primary" onClick={() => handleSubmit()} disabled={saving}>
            {saving ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>

        <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete collection?</Modal.Title>
          </Modal.Header>
          <Modal.Body>This will delete the collection, its pins and its tags.  Are you sure?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => handleDelete()} disabled={deleting}>
              {deleting ? "Deleting..." : "Delete"}
            </Button>

          </Modal.Footer>
        </Modal>
      </Modal>

    </>
  );
}