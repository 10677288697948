import '../App.css';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'
import { best } from 'wcag-color'
import Icon from '../Icon';




var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function GroupItem({ group, onPress }) {


  const { jwt } = React.useContext(PinDropContext)

  return (
    <div className={"d-flex p-2 align-items-center"} key={group.id} onClick={(group) => onPress(group)}  >
      <div style={{
        backgroundImage: `url(https://pindropbackup.blob.core.windows.net/${group.id}/${group.backgroundPhoto})`,
        width: 64, height: 64,
        maskImage: `url(/images/crewmask.png)`,
        maskSize: 64,
        marginRight: 16
      }}>
        {group.backgroundPhoto && group.backgroundPhoto.length > 0 &&
          <img src={`https://pindropbackup.blob.core.windows.net/${group.id}/${group.backgroundPhoto}`} style={{ width: 64, height: 64, marginRight: 16, borderRadius: 8 }} />
        }
        {(!group.backgroundPhoto || group.backgroundPhoto.length == 0) &&
          <img src={`/images/NoPhoto.png`} style={{ width: 64, height: 64, marginRight: 16, borderRadius: 8 }} />
        }
      </div>

      <div className={"d-flex flex-column align-center w-100"}>
        <span style={{ fontWeight: "600", fontSize: 17, color: "#3A3E44", lineHeight: 1, paddingBottom: 1 }}>
          {group.name}
        </span>

        <span style={{ fontWeight: "400", fontSize: 13, color: "rgba(58, 62, 68, 0.6)", paddingBottom: 4 }}>
          {group.members} {group.members == 1 ? "member" : "members"} · {group.pins} {group.pins == 1 ? "pin" : "pins"}
        </span>

      </div>
      <div className={"d-flex  align-items-center"} >
        <Icon icon="Chevron" size={40} color="#3A3E44" />
      </div>
    </div>
  );
}