import '../App.css';

import {
    Col,
    Row,
    Button
} from 'react-bootstrap';
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState, Fragment } from 'react';
import "react-sliding-pane/dist/react-sliding-pane.css";
import { MapkitProvider, Map, useMap, Marker } from 'react-mapkit'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import uuid from 'react-uuid'
import fileDownload from 'js-file-download'
import { API_URL } from '../vars'
import { PinDropContext } from '../Context'

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)


export default function Tags() {
    const { jwt } = React.useContext(PinDropContext)

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [savedPin, setSavedPin] = useState({ "id": "1234", "title": "New Pin" });

    const [color, setColor] = useState("#aabbcc");

    const { map, mapProps, setRotation, mapkit } = useMap()

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchHandle, setSearchHandle] = useState(null)

    var loc = JSON.parse(sessionStorage.getItem("location"))
    const [location] = useState(loc)

    const [pinName, setPinName] = useState("");
    const [pinDescription, setPinDescription] = useState("");
    const [pinTags, setPinTags] = useState([]);
    const [pinLocation, setPinLocation] = useState({});
    const [remotePhoto, setRemotePhoto] = useState(null);
    const [localPhoto, setLocalPhoto] = useState(null);
    const [saving, setSaving] = useState(false);

    const [importing, setImporting] = useState(false);
    const [exporting, setExporting] = useState(false);




    

    const handleExport = (e) => {
        setExporting(true)
    
        fetch(`${API_URL}/export`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "token": jwt,
          })
        }).then(response => response.blob()).then(blob => {
          fileDownload(blob, "PindropExport.xlsx")
        })
          .catch((err) => {
            console.log("Error exporting pins", err)
          }).finally(() => {
            // refreshAll()
            setExporting(false)
          })
    
      }
    

    return (





<Row>

    <Col lg={12}>
        <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                <span class="title" style={{paddingBottom: 8}}>
                    Data
                </span>
                <span class="subtitle" style={{paddingBottom: 24}}>
                Bulk import and export your data.
                </span>

            <div class="d-flex justify-content-center align-items-center" style={{ flexDirection: "row" }}>
                <Button size='lg' disabled={exporting} variant="outline-dark"
                    onClick={() => console.log("Import")} style={{ marginRight: 16, color: "#54AD95", borderColor: "#54AD95" }}>
                    <FontAwesomeIcon color={"#54AD95"} icon={light('file-import')} size={32} style={{ paddingRight: 4 }} />
                    Import
                </Button>
                <Button size='lg' disabled={exporting} variant="outline-dark"
                    onClick={() => handleExport()} style={{ marginRight: 16, color: "#54AD95", borderColor: "#54AD95" }}>
                    <FontAwesomeIcon color={"#54AD95"} icon={light('file-export')} size={32} style={{ paddingRight: 4 }} />
                    Export
                </Button>
            </div>


        </div>
    </Col>
    {/* <Col lg={2}>
        <div class="d-flex flex-column min-vh-100 align-items-center">
            <span class="title" style={{paddingBottom: 8}}>
                Data
            </span>
            <span class="subtitle" style={{paddingBottom: 24}}>
            Bulk import and export your data.
            </span>
            </div>
    </Col> */}
        </Row>




    );
}