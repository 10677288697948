import '../App.css';
import { Map, Marker, useMap } from 'react-mapkit'
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import Icon from "../Icon";
import { PinDropContext } from '../Context'
import { PinItem } from './PinItem';
import { NewPin } from './NewPin';
import {
  Button, Row, Col, Modal, Carousel, Card, Container
} from 'react-bootstrap';
import { getDistance, decimalToSexagesimal } from 'geolib';
import { API_URL } from '../vars'
var Spinner = require('react-spinkit');


var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function Pin({ pin, onClose, map, pinTags }) {

  const { jwt, rBush, relationships, refreshPins, media, tags } = React.useContext(PinDropContext)

  // let pt = tags.filter(tag => tagMappings.filter(item => item.pin_id == pin.id).map(t => t.tag_id).includes(pin.id))
  let tms = relationships.filter(item => item.pin_id == pin.id).map(t => t.tag_id)
  const [pinPhoto, setPinPhoto] = useState("");
  const [w3w, setWhat3Words] = useState(null)
  const [showMedia, setShowMedia] = useState(false)
  const [editPin, setEditPin] = useState(false)
  const [favourite, setFavourite] = useState(pin.favourite)
  const { mapProps, setRotation, mapkit } = useMap()
  const [sendingDirections, setSendingDirections] = useState(false)


  const onShare = () => {
    if (navigator.share) {

      const firstTag = tags.filter(t => pin.tag_ids.includes(t.id))

      fetch(`${API_URL}/share`, {
        // fetch('http://localhost:7071/share', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "token": jwt,
          isPublic: false,
          "type": "pin",

          "item": {
            id: pin.id,
            name: pin.name,
            lat: pin.lat,
            lng: pin.lng,
            description: pin.description,
            heroImage: pin.heroImage ? pin.id + "/" + pin.heroImage : "",
            type: "pin",
            tag: { name: firstTag ? firstTag["name"] : "Uncategorised", color: firstTag ? firstTag["color"] : "#54AD95" },
          }
        })
      }).then((response) => response.json())
        .then(async (json) => {
          if (json["code"]) {

            navigator.share({
              title: pin.name,
              text: `I shared the Pin '${pin.name}' with you on Pin Drop`,
              url: `https://pindrop.me/pin/${json["code"]}`
            })
              .then(() => console.log('Successful share'))
              .catch((error) => console.log('Error sharing', error));
          }
        })


    }
  }

  const handleFavourite = () => {

    fetch(`${API_URL}/saveWebData`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "token": jwt,
        "entity": pin,
        "type": "favourite"
      })
    }).then(response => response.json())
      .then(data => {
        setFavourite(!favourite)

        console.log(data)
        if (data["success"]) {
          const ePin = rBush.all().find(p => p.id === pin.id)
          console.log(ePin.favourite)
          rBush.remove(ePin)
          ePin.favourite = !ePin.favourite
          rBush.insert(ePin)
        }
        if (!data["success"]) {
          alert(data["message"])
        }
      })

  }

  const handleDirections = () => {


    setSendingDirections(true)
    fetch(`${API_URL}/sendDirections`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "token": jwt,
        "lat": pin.lat,
        "lng": pin.lng,
        "pinName": pin.name,
      })
    }).then(response => response.json())
      .then(data => {
        if (data["success"]) {
          console.log("Posted to phone")
          setSendingDirections(false)
        }
        if (!data["success"]) {
          alert(data["message"])
        }
      })

  }

  useEffect(() => {
    if (pin.heroImage && pin.heroImage.length > 0 && !pin.orgID) {
      setPinPhoto(`${API_URL}/myMedia?file=${pin.id}/${pin.heroImage}&token=${jwt}`)
    }
    if (pin.heroImage && pin.heroImage.length > 0 && pin.orgID) {
      setPinPhoto(`https://pindropbackup.blob.core.windows.net/${pin.orgID}/${pin.heroImage}`)
    }
    if (!pin.heroImage || pin.heroImage.length == 0) {
      setPinPhoto(`/images/NoPhoto.png`)
    }
    fetch(`https://api.what3words.com/v3/convert-to-3wa?coordinates=${pin.lat}%2C${pin.lng}&key=MG3ILJBP`)
      .then((response) => response.json())
      .then((json) => {
        setWhat3Words(`${json.words}`);
      })
      .catch((error) => {
        setWhat3Words("Can't get what3words");

        console.error(error);
      });

  }, [pin, jwt])

  return (
    <div style={{ padding: 16 }}>
      <Card style={{ borderRadius: 12, width: 380, height: 250, padding: 0, marginRight: 16, marginBottom: 8 }}
        onClick={() => console.log("close")}>
        <Card.Img style={{
          borderRadius: 12, filter: "brightness(0.8)",
          objectFit: "cover"
        }} height={250} width={380}
          src={pinPhoto} />
        <Card.ImgOverlay style={{}}>
          <a href='#' onClick={() => onClose()} style={{ position: "absolute", top: "12px", right: "0px", padding: "10px" }}>
            <Icon icon="CollapseRight" size={40} color={"white"} style={{ position: "absolute", top: 8, right: 20 }} />
          </a>
        </Card.ImgOverlay>
      </Card>


      <div className={"d-flex align-items-start"} style={{ marginLeft: -16, paddingTop: 8, paddingBottom: 8 }}>
        <PinItem pin={pin} onPress={() => { }} pinCard pinTags={pinTags} />
      </div>

      <Row style={{ paddingTop: 8, paddingBottom: 24 }}>
        <Col>
          <Button onClick={() => handleFavourite()} variant="outline-secondary" style={{ paddingLeft: 16, paddingRight: 16, borderRadius: 8, borderColor: "#cccccc" }}>
            {!favourite &&
              <Icon icon="FavouriteOutline" size={40} color={"#3A3E44"} />
            }
            {favourite &&
              <Icon icon="FavouriteFilled" size={40} color={"#FF5D55"} />
            }
          </Button>

        </Col>
        <Col>
          <Button onClick={() => setEditPin(pin)} variant="outline-secondary" style={{ paddingLeft: 16, paddingRight: 16, borderRadius: 8, borderColor: "#cccccc" }}>
            <Icon icon="Edit" size={40} color={"#3A3E44"} />
          </Button>

        </Col>
        <Col>
          <Button onClick={() => onShare()} variant="outline-secondary" style={{ paddingLeft: 16, paddingRight: 16, borderRadius: 8, borderColor: "#cccccc" }}>
            <Icon icon="Share" size={40} color={"#3A3E44"} />
          </Button>

        </Col>
        <Col>
          <Button onClick={() => handleDirections()} variant="outline-secondary" style={{ paddingLeft: 16, paddingRight: 16, borderRadius: 8, borderColor: "#cccccc" }}>
            {sendingDirections &&
              <Spinner name="double-bounce" color="#54AD95" style={{ width: 40, height: 40 }} />
            }
            {!sendingDirections &&
              <Icon icon="Directions" size={40} color={"#3A3E44"} />
            }
          </Button>

        </Col>
      </Row>
      {pin.description && pin.description.length > 0 &&
        <div style={{ paddingBottom: 24 }}>
          <span className='pinDescription'>{pin.description}</span>
        </div>
      }

      <div style={{ paddingTop: 16, paddingBottom: 16 }} className="border-top">
        <span className="form-label">Attachments</span>
        <div className="d-flex flex-wrap">
          <div key={"addMedia"} className="border d-flex"
            style={{ width: 64, height: 64, marginRight: 8, marginBottom: 8, borderRadius: 8, borderWidth: 1, alignItems: "center", justifyContent: "center" }}
            onClick={() => alert("Show media add")}>
            <Icon icon="BigPlus" size={40} color={"#3A3E44"} />
          </div>

          {media.filter(item => item.pin_id == pin.id).map((item, index) => {
            return (
              <div key={index} style={{ width: 64, height: 64, marginRight: 8, marginBottom: 8, borderRadius: 8, overflow: "hidden" }} onClick={() => setShowMedia(true)}>
                <img src={`${API_URL}/myMedia?file=${item.pin_id}/${item.file}&token=${jwt}`} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
              </div>
            )
          })}
        </div>
      </div>

      <div style={{ paddingBottom: 16, paddingTop: 16 }} className="border-top">
        <span className="form-label">what3words</span>
        <div>
          <span style={{ color: "#FF5D55" }}>///</span> {w3w}
        </div>
      </div>

      <div style={{ paddingBottom: 16 }}>
        <span className="form-label">Latitude / Longitude</span>
        <div className="pinText">{decimalToSexagesimal(pin.lat)} {decimalToSexagesimal(pin.lng)}</div>
      </div>

      <div style={{ paddingBottom: 16 }}>
        <span className="form-label">GPS</span>
        <div className="pinText">{pin.lat.toPrecision(7)}, {pin.lng.toPrecision(7)}</div>
      </div>
      <Row className='border-top' style={{ paddingTop: 16 }}>
        <Col>
          <div style={{ borderTopWidth: 1, borderTopColor: "#cccccc" }}>
            <span className="form-label">Pin dropped</span>
            <div className="pinText">{dayjs(pin.date_dropped).format('D MMM YYYY')}</div>

          </div>

        </Col>
        <Col className='border-left' style={{ paddingLeft: 8 }}>
          <div >
            <span className="form-label">Last edit</span>
            <div className="pinText">{dayjs(pin.date_updated).format('D MMM YYYY')}</div>
          </div>

        </Col>
      </Row>

      <Modal show={showMedia} onHide={() => setShowMedia(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Attachments
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel>
            {media.filter(item => item.pin_id == pin.id).map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <img

                    className="d-block w-100"
                    src={`${API_URL}/myMedia?file=${item.pin_id}/${item.file}&token=${jwt}`}
                    alt="First slide"
                  />
                </Carousel.Item>
              )
            })}
          </Carousel>
          {/* <Modal.Footer>
            <Button onClick={() => setShowMedia(false)}>Close</Button>
          </Modal.Footer> */}
        </Modal.Body>
      </Modal>

      {editPin &&
        <NewPin show={editPin} onClose={() => {
          setEditPin(false);
        }} visible={editPin} editPin={editPin} map={map} mapkit={mapkit} newPin={editPin} />
      }

    </div>

  );
}