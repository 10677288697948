import '../App.css';
import Modal from 'react-bootstrap/Modal';

import React, { useEffect, useState, Fragment, useCallback } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'
import { PinItem } from '../components/PinItem';
import Icon from '../Icon';
import { PinChooser } from '../components/PinChooser';
import UnsplashReact, { Base64Uploader, withDefaultProps } from "unsplash-react"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useQueryClient } from 'react-query'
import { best } from 'wcag-color'


import uuid from 'react-uuid'
import { FileUploader } from "react-drag-drop-files";

import {
  Button, Row, Col, FloatingLabel, Alert
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { Map, Marker, useMap } from 'react-mapkit'
import 'react-bootstrap-typeahead/css/Typeahead.css';


import { API_URL } from '../vars'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function AddToTag({ visible, onClose, tag, tagSelectedPins }) {

  const { jwt, pins, tags } = React.useContext(PinDropContext)

  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [privacy, setPrivacy] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [showError, setShowError] = useState(false)
  const [localPhotos, setLocalPhotos] = useState([]);
  const [remotePhotos, setRemotePhotos] = useState([]);
  const [selectedPins, setSelectedPins] = useState([]);
  const [saving, setSaving] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false)
  const queryClient = useQueryClient()

  const [allSelectedPins, setAllSelectedPins] = useState([]);


  React.useEffect(() => {

    let lpins = []
    
    setAllSelectedPins(pins.filter(p => p.tag_ids.includes(tag.id)  ))

  }, [tagSelectedPins])





  const handleSubmit = (e) => {



    setSaving(true)



    let bulkToOrg = []

    allSelectedPins.map(pin => {

      tags.filter(tag => pin.tag_ids.includes(tag.id)).map(tag => {
        if (bulkToOrg.filter(b => b.id === tag.id).length === 0) {
          bulkToOrg.push({ "name": tag.name, "color": tag.color, "id": tag.id, "type": "tag" })
        }
      })

      bulkToOrg.push({
        "name": pin.name, "id": pin.id, "type": "pin", "description": pin.description,
        "lat": pin.lat, "lng": pin.lng, "heroImage": pin.heroImage,
        "pinTemplate": "1", "extended": [], "tag_ids": pin.tag_ids, "date_dropped": pin.date_dropped,
        "date_updated": pin.date_updated
      }
      )
    })

    selectedTags.map(tag => {
      if (bulkToOrg.filter(b => b.id === tag.id).length === 0) {

        bulkToOrg.push({ "name": tag.name, "color": tag.color, "id": tag.id, "type": "tag" })
      }
    })


    fetch(`${API_URL}/orgPost`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "token": jwt,
        "orgID": tag.id,
        "entities": bulkToOrg,
      })
    })
      .catch((err) => {
        console.log("Error uploading pins", err)
      }).finally(() => {
        queryClient.invalidateQueries(['collections']).then(() => {
          queryClient.refetchQueries(['collections']).then(() => {
            setSaving(false)

            onClose()
          })
        })
        console.log("done uploading")
      })






  }



  return (
    <>


      <Modal show={visible} onHide={() => onClose()}
        dialogClassName="modal-70w"
        animation={false}
      >
        <Modal.Header closeButton>


          <Modal.Title>
            Add to Tag
          </Modal.Title>

        </Modal.Header>
        <Modal.Body >
          {showError &&
            <Alert key={"danger"} variant={"danger"}>
              There was a problem saving your tag. Please try again later, or <Alert.Link href="mailto:hello@pindropapp.com" >contact us</Alert.Link> if this keeps happening.
            </Alert>
          }
          <Form>
            <Row style={{paddingLeft: 12}}>
              <Col lg={6} style={{ backgroundColor: tag.color, borderRadius: 12 }}>
              <div class="d-flex align-items-center justify-content-center h-100" style={{flexDirection: "column"}}>
              {!tag.icon &&
          <Icon icon={"Tag"} size={120} color={best('#ffffff', '#3A3E44', tag.color)} />
          }

          {tag.icon &&
           <i class={`fa-solid fa-${tag.icon} fa-6x`} style={{ color: best('#ffffff', '#3A3E44', tag.color)}} ></i>
          }

<div style={{ color: best('#ffffff', '#3A3E44', tag.color), fontWeight: "700", fontSize: 24, paddingTop: 16 }}>{tag.name}</div>
                  <div style={{ color: best('#ffffff', '#3A3E44', tag.color), fontWeight: "400", fontSize: 13, alignContent: "center" }}>
                    {allSelectedPins.length} {allSelectedPins.length == 1 ? "pin" : "pins"}</div>
                </div>
              </Col>
              <Col lg={6} >

                <PinChooser
                  setSelectedPins={(e) => setAllSelectedPins(e)}
                  setSelectedTags={(e) => setSelectedTags(e)}
                  selectedPins={allSelectedPins}
                  selectedTags={selectedTags}
                  bulk
                />

              </Col>
            </Row>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => onClose()}>
            Cancel
          </Button>

          <Button variant="primary" onClick={() => handleSubmit()} disabled={saving}>
            {saving ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>

      </Modal>

    </>
  );
}