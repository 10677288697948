import '../App.css';
import Modal from 'react-bootstrap/Modal';

import React, { useEffect, useState, Fragment, useCallback } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'
import Icon from '../Icon'
import uuid from 'react-uuid'
import { FileUploader } from "react-drag-drop-files";

import {
  Button, Row, Col, FloatingLabel, Alert
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ReactTags from 'react-tag-autocomplete'
import Switch from "react-switch";
import { Map, Marker, useMap } from 'react-mapkit'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { best } from 'wcag-color'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, light } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";

import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import { API_URL } from '../vars'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function NewPin({ visible, onClose, newPin, map, mapkit, editPin }) {
  const { mapProps } = useMap()

  const { jwt, pins, tags, relationships, location, rBush, setPins, setRefreshPins, subscription } = React.useContext(PinDropContext)
  const [show, setShow] = useState(false);
  const [dragging, setDragging] = useState(false);
  const filterBy = () => true;
  const mapRef = React.useRef(null);

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDragEnter: () => {
      setDragging(true)
    },
    onDragLeave: () => {
      setDragging(false)
    },
    maxFiles: 20,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
    }
  });

  const [searchHandle, setSearchHandle] = useState(null)
  const [address, setAddress] = useState(null)
  const [searchResults, setSearchResults] = useState(null)
  const navigate = useNavigate()

  const [localMap, setLocalMap] = useState(null)
  const [w3w, setWhat3Words] = useState(null)

  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [dateDropped, setDateDropped] = useState(null)
  const [timeDropped, setTimeDropped] = useState(null)
  const [pinLocation, setPinLocation] = useState(null)
  const [privacy, setPrivacy] = useState(null)
  const [notifications, setNotifications] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])
  const [showError, setShowError] = useState(false)
  const [localPhotos, setLocalPhotos] = useState([]);
  const [remotePhotos, setRemotePhotos] = useState([]);


  const [height, setHeight] = useState(0);
  const [backdropStyle, setBackdropStyle] = useState(null);
  const [width, setWidth] = useState(0);
  const elementRef = React.useRef(null);

  const [currentDate, setCurrentDate] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [saving, setSaving] = useState(false);


  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [showSaved, setShowSaved] = useState(false)
  const [showUpgrade, setShowUpgrade] = useState(false)

  const gracefulClose = (saved) => {
    if (subscription !== "Base") {
      onClose()
    } else {
      if (saved) {
        onClose()
        navigate(`/upgrade?pinName=${name}`)
      } else {
        onClose()
        navigate('/upgrade')
      }
    }
  }

  const handleDelete = (e) => {

    setDeleting(true)
    fetch(`${API_URL}/saveWebData`, {
      method: 'POST',
      body: JSON.stringify({
        "token": jwt,
        "type": "delete",
        "pins": [editPin.id]
      })
    }).then(res => res.json()).then(data => {


      setPins(pins.filter(p => p.id !== editPin.id))
      gracefulClose()

    }).finally(() => {
      setDeleting(false)
    }).catch(err => {
      console.log(err)
    })


  }


  const onPhotoChange = (e) => {

    if (e.length > 1) {
      console.log(e)
      const lFiles = []
      for (let i = 0; i < e.length; i++) {
        const f = e[i]
        setRemotePhotos([...remotePhotos, f])
        const file = URL.createObjectURL(f)
        console.log(file)
        setLocalPhotos([...localPhotos, file])

      }


    } else {

      setRemotePhotos([...remotePhotos, e])
      const file = URL.createObjectURL(e)
      console.log(file)
      setLocalPhotos([...localPhotos, file])
    }
  }


  useEffect(() => {
    if (!confirmDelete && !showUpgrade && !showSaved) {
      setBackdropStyle(null)
    }
    if (showUpgrade || showSaved || subscription == "Base") {
      setBackdropStyle("blurBackground")
    }
    if (confirmDelete) {
      setBackdropStyle("brightness(50%)")
    }

  }, [confirmDelete, showUpgrade, showSaved, subscription])

  useEffect(() => {
    if (editPin) {
      setName(editPin.name)
      setDescription(editPin.description)
      setDateDropped(dayjs(editPin.date_dropped).format("YYYY-MM-DD"))
      setTimeDropped(dayjs(editPin.date_dropped).format("HH:MM"))
      // console.log(tags.filter(t => editPin.tag_ids.includes(t.id)) )
      setSelectedTags(tags.filter(t => editPin.tag_ids.includes(t.id)))
      setPrivacy(editPin.privacy == 1 ? true : false)
    }

    if (!editPin) {
      setDateDropped(dayjs().format("YYYY-MM-DD"))
      setTimeDropped(dayjs().format("HH:mm"))
    }
  }, [visible, editPin])

  useEffect(() => {
    if (!showUpgrade && !showSaved) {
      setHeight(elementRef.current.clientHeight);
      setWidth(elementRef.current.clientWidth);
    }
  }, [showUpgrade, showSaved]);

  const handleNotification = (notification) => {
    setNotifications(notification)
  }



  const onDelete = useCallback((tagIndex) => {
    setSelectedTags(selectedTags.filter((_, i) => i !== tagIndex))
  }, [selectedTags])

  const onAddition = useCallback((newTag) => {
    setSelectedTags([...selectedTags, newTag])
  }, [selectedTags])


  const handleSubmit = (e) => {

    const pin_id = uuid()
    setSaving(true)

    console.log("tags", selectedTags.map(t => t.id))
    fetch(`${API_URL}/saveWebData`, {
      method: 'POST',
      body: JSON.stringify({
        token: jwt,
        type: "pin",
        entity: {
          "id": editPin ? editPin["id"] : pin_id,

          "picture": null,
          "name": name,
          "favourite": false,
          "description": description,
          "heroImage": editPin ? editPin["heroImage"] : "",
          "lat": pinLocation.lat,
          "lng": pinLocation.lng,
          "date_updated": `${dateDropped} ${timeDropped}`,
          "date_dropped": `${dateDropped} ${timeDropped}`,
          "geofence": notifications,
          "isOwner": true,
          "canEdit": true,
          "isOrg": false,
          "isCollection": false,
          "isCrew": false,
          "pinTemplate": null,
          "sourcePin": null,
          "isSecret": privacy == "Private" ? true : false,
          "tag_ids": selectedTags.map(t => t.id)
        },
      })
    }).then(res => res.json()).then(data => {
      console.log(data)

      if (editPin) {
        const ePin = rBush.all().find(p => p.id === editPin.id)
        console.log("edit pin", ePin)
        rBush.remove(ePin);
      }

      rBush.insert({
        minX: pinLocation.lat, maxX: pinLocation.lat, minY: pinLocation.lng, maxY: pinLocation.lng, id: editPin ? editPin.id : pin_id,
        name: name, lat: pinLocation.lat, lng: pinLocation.lng, heroImage: "", tag_ids: selectedTags.map(t => t.id),
        date_dropped: dateDropped, date_updated: dateDropped, isSecret: privacy == "Private" ? true : false,
        favourite: false, description: description,
        orgId: null, isOrg: false, isCollection: false,
        isCrew: false, isOwner: true, canEdit: true
      })


      setRefreshPins(Math.random())

      // const tmpRel = []
      // selectedTags.map(t => t.id).map(tag_id => {
      //   tmpRel.push({
      //     pin_id: pin_id,
      //     tag_id: tag_id
      //   })
      // }
      // )

      // setRelationships([...relationships, ...tmpRel])

      // setPins([...pins, {
      //   minX: pinLocation.lat, maxX: pinLocation.lat, minY: pinLocation.lng, maxY: pinLocation.lng, id: uuid(),
      //   name: name, lat: pinLocation.lat, lng: pinLocation.lng, heroImage: "", tag_ids: selectedTags.map(t => t.id),
      //   date_dropped: dateDropped, date_updated: dateDropped, isSecret: privacy == "Private" ? true : false,
      //   favourite: false, description: description,
      //   orgId: null, isOrg: false, isCollection: false,
      //   isCrew: false, isOwner: true, canEdit: true
      // }])

      // This is a close from Save

    }).finally(() => {
      setSaving(false)
      gracefulClose(true)
    }).catch(err => {
      console.log(err)
      setShowError(true)
    })


  }


  function SuggestionComponent({ item, query }) {
    const ltag = tags.find(t => t.id == item.id)

    return (
      <span key={ltag.id} className={item.name === query ? 'match' : 'no-match'} style={{ color: best('#ffffff', '#3A3E44', ltag.color), fontSize: 13, marginRight: 4, backgroundColor: ltag.color, paddingRight: 8, paddingLeft: 8, paddingTop: 4, paddingBottom: 2, borderRadius: 4, marginBottom: 4 }}>
        {ltag.name}
      </span>
    )
  }

  function TagComponent({ tag, removeButtonText, onDelete }) {
    const ltag = tags.find(t => t.id == tag.id)
    return (
      <span key={tag.id} onClick={() => onDelete(tag)} style={{ color: best('#ffffff', '#3A3E44', ltag.color), fontSize: 13, marginRight: 4, backgroundColor: ltag.color, paddingRight: 8, paddingLeft: 8, paddingTop: 2, paddingBottom: 2, borderRadius: 4, marginBottom: 4 }}>
        {ltag.name}
        <FontAwesomeIcon icon={light('xmark')} style={{ color: best('#ffffff', '#3A3E44', ltag.color), fontSize: 13, marginLeft: 4 }} />
      </span>

    )
  }

  useEffect(() => {


    if (mapkit && visible && !localMap) {
      console.log("Initialising map")
      setCurrentDate(dayjs().format("YYYY-MM-DD"))
      setCurrentTime(dayjs().format("HH:mm"))


      var mapNewPin = new mapkit.Map('mapContainer', { center: new mapkit.Coordinate(newPin && newPin.lat ? newPin.lat : (location ? location.latitude : 0), newPin && newPin.lng ? newPin.lng : (location ? location.longitude : 0)) });

      setLocalMap(mapNewPin)

      if (newPin && newPin.lat) {
        if (newPin.name) {
          setName(newPin.name)
        }
        if (newPin.description) {
          setDescription(newPin.description)
        }

        var newCenter = new mapkit.Coordinate(newPin.lat, newPin.lng);
        var span = new mapkit.CoordinateSpan(.01);
        var region = new mapkit.CoordinateRegion(newCenter, span);
        mapNewPin.setRegionAnimated(region)

      } else {
        var newCenter = new mapkit.Coordinate(location ? location.latitude : 0, location ? location.longitude : 0);
        var span = new mapkit.CoordinateSpan(.01);
        var region = new mapkit.CoordinateRegion(newCenter, span);
        map.setRegionAnimated(region)

      }

      mapNewPin.addEventListener('region-change-end', (e) => {

        var geocoder = new mapkit.Geocoder({
          language: "en-GB",
          getsUserLocation: true
        }).reverseLookup(new mapkit.Coordinate(e.target.center.latitude, e.target.center.longitude), (err, data) => {
          setAddress(data.results[0].formattedAddress)
        });


        setPinLocation({ lat: e.target.center.latitude, lng: e.target.center.longitude })
        // console.log(e.target.center)
        fetch(`https://api.what3words.com/v3/convert-to-3wa?coordinates=${e.target.center.latitude}%2C${e.target.center.longitude}&key=MG3ILJBP`)
          .then((response) => response.json())
          .then((json) => {
            setWhat3Words(`${json.words}`);
          })
          .catch((error) => {
            setWhat3Words("Can't get what3words");

            console.error(error);
          });

      }
      )


      // var search = new mapkit.Search({ region: localMap.region });
      // setSearchHandle(search)


    }
  }, [visible, mapkit, localMap]);


  useEffect(() => {

    if (acceptedFiles.length > 1) {
      const lFiles = []
      const rFiles = []

      for (let i = 0; i < acceptedFiles.length; i++) {
        const f = acceptedFiles[i]
        rFiles.push(f)
        const file = URL.createObjectURL(f)
        lFiles.push(file)
        console.log(file)
      }

      setRemotePhotos([...remotePhotos, ...rFiles])
      setLocalPhotos([...localPhotos, ...lFiles])

    }

    if (acceptedFiles.length == 1) {

      setRemotePhotos([...remotePhotos, acceptedFiles])
      const file = URL.createObjectURL(acceptedFiles)
      console.log(file)
      setLocalPhotos([...localPhotos, file])
    }

    // for (let i = 0; i < acceptedFiles.length; i++) {
    //   const f = acceptedFiles[i]
    //   setRemotePhotos([...remotePhotos, f])
    //   const file = URL.createObjectURL(f)
    //   console.log(file)
    //   setLocalPhotos([...localPhotos, file])
    // }


    setDragging(false)
  }, [acceptedFiles])

  return (
    <>


      <Modal show={visible} onHide={() => gracefulClose(false)}
        dialogClassName={(!showUpgrade && !showSaved) ? "modal-90w" : null}
        animation={false}
        centered

      >
        <Modal.Header closeButton>
          <Modal.Title style={{ width: "100%", justifyItems: "center" }}>
            {editPin ? "Edit Pin" : "Pin Drop"}
          </Modal.Title>
        </Modal.Header>
        {showUpgrade &&
          <Modal.Body>
            <h1>Why not upgrade</h1>

          </Modal.Body>
        }
        {showSaved &&
          <Modal.Body>
            <h1>Your pin has been saved</h1>
          </Modal.Body>
        }
        {!showSaved && !showUpgrade &&
          <Modal.Body >
            {showError &&
              <Alert key={"danger"} variant={"danger"}>
                There was a problem saving your pin. Please try again later, or <Alert.Link href="mailto:hello@pindropapp.com" >contact us</Alert.Link> if this keeps happening.
              </Alert>
            }
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Name"
                      className="mb-3"
                    >
                      <Form.Control autoFocus placeholder="Give this a name" className='formInput' value={name} onChange={(e) => setName(e.target.value)} />
                    </FloatingLabel>
                  </Form.Group>
                  {false &&
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Address"
                        className="mb-3"
                      >
                        <Form.Label className='secondaryText'>{newPin.address}</Form.Label><br />

                        {/* <Form.Label style={{ fontSize: 13, color: "rgba(58, 62, 68, 0.6)" }}>
                      {newPin.lat.toFixed(7)},{newPin.lng.toFixed(7)}
                    </Form.Label> */}
                      </FloatingLabel>
                    </Form.Group>
                  }

                  {address &&
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Address"
                        className="mb-3"
                      >
                        <Form.Control placeholder="Address" className='formInput' value={address} onChange={(e) => setAddress(e.target.value)} />
                      </FloatingLabel>
                    </Form.Group>
                  }

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <ReactTags
                      placeholderText={"Tags"}
                      tags={selectedTags}
                      suggestions={tags}
                      onDelete={onDelete}
                      onAddition={onAddition}
                      tagComponent={TagComponent}


                    />
                    {/* <Typeahead
                    id="public-methods-example"
                    labelKey="name"
                    multiple
                    options={tags}
                    placeholder="Tags"
                    size='lg'
                    onChange={(selected) => setSelectedTags(selected)}
                  // ref={ref}
                  /> */}


                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Who can see this?</Form.Label>

                    <Form.Select size="lg" style={{ fontSize: 16, fontWeight: "400", height: 60 }}
                      onChange={(e) => setPrivacy(e.target.value)}
                    >
                      <option>
                        Private
                      </option>
                      <option>
                        Public
                      </option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Attachments</Form.Label>
                    <div class="d-flex align-items-center">
                      <div {...getRootProps({ className: 'dropzone w-100 h-100 p-2' })}
                        style={{
                          borderStyle: "dashed", borderColor: "lightgray",
                          borderWidth: "1px", borderRadius: 10, flexDirection: "row",
                          alignItems: "center", justifyContent: "center",
                          backgroundColor: dragging ? "rgba(0,0,0,0.1)" : "white"
                        }}>
                        <input {...getInputProps()} />
                        <div className='d-flex' style={{ alignItems: "center" }}>
                          <span className='border' style={{ padding: 10, borderRadius: 10, marginRight: 12 }} onClick={open}>
                            <Icon icon={"BigPlus"} size={40} style={{ color: "rgba(58, 62, 68, 0.6)" }} />
                          </span>
                          <span>
                            {localPhotos.length == 0 &&
                              <span style={{ paddingLeft: 16, color: "rgba(58, 62, 68, 0.6)" }}>Drag and drop files here (PNG / JPG)</span>
                            }
                          </span>
                          <div>
                            {localPhotos && localPhotos.map((photo, index) => {

                              return (
                                <img width={60} height={60} src={photo}
                                  onClick={() => {
                                    setLocalPhotos(localPhotos.filter((_, i) => i !== index))
                                  }}

                                  style={{ zIndex: 9999, marginBottom: 16, objectFit: "cover", borderRadius: 8, borderWidth: 1, borderColor: "gray", marginRight: 12 }} />
                              )

                            })}
                          </div>
                        </div>
                      </div>

                    </div>


                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Description"
                      className="mb-3"
                    >
                      <Form.Control
                        as="textarea"
                        style={{ height: '180px' }}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Row>
                    <Col>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Date Dropped"
                          className="mb-3"
                        >
                          <Form.Control type="date" name='dateDropped'
                            onChange={(e) => setDateDropped(e.target.value)}
                            value={dateDropped ? dateDropped : currentDate}
                          />
                        </FloatingLabel>

                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Time Dropped"
                          className="mb-3"
                        >
                          <Form.Control type="time" name='timeDropped'
                            value={timeDropped ? timeDropped : currentTime}
                            onChange={(e) => setTimeDropped(e.target.value)} />
                        </FloatingLabel>

                      </Form.Group>

                    </Col>
                  </Row>
                  <Form.Label style={{ fontSize: 13, color: "rgba(58, 62, 68, 0.6)" }}>
                    Pin settings
                  </Form.Label>
                  <div class="d-flex justify-content-between">
                    <div class=" d-flex justify-content-start formLabelSwitch">
                      Notify me when nearby
                    </div>
                    <div class="d-flex justify-content-end">
                      <Switch width={51} height={31} onColor='#54AD95' onChange={(val) => setNotifications(val)} checked={notifications} uncheckedIcon={false} checkedIcon={false} />
                    </div>
                  </div>
                </Col>
                <Col style={{ position: "relative" }}>

                  <img width="38" height="60" src="/images/DropPin.png" style={{ position: "absolute", left: (width / 2) - 8, top: (height / 2) - 50, zIndex: 99999 }} />
                  {w3w &&
                    <div style={{ position: "absolute", bottom: 50, left: (width - 500) / 2, width: "100%", zIndex: 99999 }}>
                      <div
                        style={{ textAlign: "center", width: 500, color: "#3A3E44", fontSize: 16, fontWeight: "400", backgroundColor: "rgba(255, 255, 255, 0.75)", padding: 12, borderRadius: 12 }} >
                        <span style={{ color: "#FF5D55" }}>///</span> {w3w}
                      </div>
                    </div>
                  }
                  <div ref={elementRef} id="mapContainer" style={{ width: "100%", height: "100%" }}>
                  </div>

                </Col>
              </Row>
            </Form>

          </Modal.Body>
        }

        {!showSaved && !showUpgrade &&

          <Modal.Footer>
            {editPin &&
              <Button variant="danger" onClick={() => setConfirmDelete(true)}>
                Delete
              </Button>
            }

            <Button variant="outline-dark" onClick={() => gracefulClose(false)}>
              Cancel
            </Button>
            <Button variant="primary" disabled={saving} onClick={() => handleSubmit()}>
              {saving ? "Saving..." : "Save"}
            </Button>
          </Modal.Footer>
        }
        <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete pin?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this pin?  This cannot be undone.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => handleDelete()} disabled={deleting}>
              {deleting ? "Deleting..." : "Delete"}
            </Button>
          </Modal.Footer>

        </Modal>

      </Modal>

    </>
  );
}