import '../App.css';
import { Map, Marker, useMap } from 'react-mapkit'
import {
  Col,
  Row
} from 'react-bootstrap';
import React, { useEffect, useState, Fragment } from 'react';

import { NewTag } from '../components/NewTag';
import { Tag } from '../components/Tag';
import { TagItem } from '../components/TagItem';
import { Search } from '../components/Search';
import { best } from 'wcag-color'
import { PinDropContext } from '../Context'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Icon from "../Icon";
import { useNavigate, useParams } from 'react-router-dom';
import { size } from 'lodash';

var _ = require('lodash');


export default function Pins() {


  const { location, pins, tags, relationships, loadingPins, setLoadingPins, jwt } = React.useContext(PinDropContext)

  const [subscription] = useState(sessionStorage.getItem("subscription"));
  const [showMap, setShowMap] = useState(true);
  const [newTag, setNewTag] = useState(false);
  const [newPin, setNewPin] = useState(false);
  const [showTag, setShowTag] = useState(false);
  const { map, mapProps, setRotation, mapkit } = useMap()
  const [initialised, setInitialised] = useState(false);
  const [selectedTagID, setSelectedTagID] = useState(null);
  const [showSearch, setShowSearch] = useState(true);
  const [tagPins, setTagPins] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [evSet, setEVSet] = useState(false);
  const params = useParams();
  let navigate = useNavigate();




  var calloutDelegate = {
    // Return a div element and populate it with information from the
    // annotation, including a link to a review site.
    calloutContentForAnnotation: function(annotation) {
        var element = document.createElement("div");
        element.className = "review-callout-content";
        var title = element.appendChild(document.createElement("h1"));
        title.textContent = annotation.title;
        var link = element.appendChild(document.createElement("a"));
        link.href = annotation.data;
        link.textContent = "Review";
        // Add more content.
        return element;
    }
};

  useEffect(() => {
    if (params && params.id) {
      setSelectedTagID(params.id)
      setShowTag(tags.find(tag => tag.id === params.id))

      navigate('/tags')
    }

  }, [params]);

  useEffect(() => {

    if (map && mapkit) {

      // if (location) {
      //   var newCenter = new mapkit.Coordinate(location.latitude, location.longitude);
      //   var span = new mapkit.CoordinateSpan(.2);
      //   var region = new mapkit.CoordinateRegion(newCenter, span);
      //   map.setRegionAnimated(region)
      // }

      map.showsUserLocation = true


      if (tagPins.length > 0) {
        const ms = []
        map.removeAnnotations(markers)

        setMarkers([])

        console.log(tagPins)
        tagPins.map(pin => {
          var newCoord = new mapkit.Coordinate(pin.lat, pin.lng);
          var newMarker = new mapkit.MarkerAnnotation(
            newCoord
                  
          );

          newMarker.title = pin.title;
          newMarker.data = pin.id
          newMarker.color = tags.find(t => pin.tag_ids.includes(t.id)) ? tags.find(t => pin.tag_ids.includes(t.id)).color : "black"
          newMarker.glyphColor = "white"
          newMarker.glyphImage = { 
            1: showTag.icon ? `/mapIcons/${showTag.icon}.svg` : "/Icon/MapPinLight.png", 
            2: showTag.icon ? `/mapIcons/${showTag.icon}.svg` : "/Icon/MapPinLight@2x.png", 
            3: showTag.icon ? `/mapIcons/${showTag.icon}.svg` : "/Icon/MapPinLight@3x.png" 
          }
          newMarker.clusteringIdentifier = "me"

          ms.push(newMarker)
          map.addAnnotation(newMarker)
        })

        map.showItems(ms, {
          animate: true,
          padding: new mapkit.Padding(60, 25, 60, 25)
        })

        setMarkers(ms)



      }

      if (!evSet) {
        map.addEventListener("select", function configurationChanged(ev) {
          setSelectedTagID(ev.annotation.data)

          console.log(ev.annotation.data)
          console.log(ev.annotation.coordinate.latitude, ev.annotation.coordinate.longitude)
        });

        map.addEventListener("long-press", function configurationChanged(ev) {
          let coord = map.convertPointOnPageToCoordinate(ev.pointOnPage)
          console.log(coord.latitude, coord.longitude)
          var geocoder = new mapkit.Geocoder({
            language: "en-GB",
            getsUserLocation: true
          }).reverseLookup(new mapkit.Coordinate(coord.latitude, coord.longitude), (err, data) => {
            console.log(data.results[0].formattedAddress);
            setShowSearch(false)
            setNewPin({ lat: coord.latitude, lng: coord.longitude, address: data.results[0].formattedAddress })

          });


        });

        setEVSet(true)
      }

      // map.setRegion({ latitude: location.lat, longitude: location.lng, latitudeDelta: 0.01, longitudeDelta: 0.01 })


    }
  }, [map, mapkit, location, pins, tagPins]);




  useEffect(() => {


    if (selectedTagID) {
      console.log(selectedTagID)
      // setShowSearch(false)
      // setShowTag(tags.find(t => t.id == selectedTagID))



      setTagPins(pins.filter(p => p.tag_ids.includes(selectedTagID)))

      console.log(pins.filter(p => p.tag_ids.includes(selectedTagID)).length)
    }
  }, [selectedTagID, tags]);

  return (
    <Row className={"map"} style={{ width: "100%", height: "90%" }}>
      <Col >



        <Search map={map} mapkit={mapkit} />

        <Map tracksUserLocation center={[location ? location.latitude : 0, location ? location.longitude : 0]} {...mapProps} showsUserLocation>

        </Map>

      </Col>
      <div class="pinListContainer" >
        <div class="d-flex justify-content-between" style={{ paddingLeft: 8 }}>
          <div class=" d-flex justify-content-start title">
            Tags
          </div>
          {!loadingPins &&
            <div class="d-flex justify-content-end" style={{ marginRight: -16 }}>
              <a href="#" onClick={() => {
                setNewTag(true)
              }} >
                <Icon icon="BigPlus" size={40} />
              </a>
            </div>
          }
        </div>


        <div class="pinList" style={{ width: 400 }}>


          {tags && _.sortBy(tags, ['name']).map((tag, i) => {
            return (
              <TagItem

                tag={tag}
                onPress={() => {
                  setSelectedTagID(tag.id);
                  setShowTag(tag)
                }}

              />
            )
          })
          }
        </div>
      </div>

      <Offcanvas show={showTag}
        placement={"end"}
        backdrop={false}
        onHide={() => {
          setShowTag(false);
          setSelectedTagID(null);


        }}
        style={{ width: 425, padding: 0 }}>
        <Offcanvas.Body style={{ padding: 0 }}>
          {showTag &&
            <>

              <Tag tag={showTag} onClose={() => {

                setShowTag(false);
                setSelectedTagID(null);

              }}

                map={map}
                mapkit={mapkit}
              />
            </>
          }
        </Offcanvas.Body>
      </Offcanvas>

      <NewTag show={newTag} onClose={(t) => {
        setNewTag(false);
        setShowTag(t)
      }} visible={newTag} />







    </Row>
  );
}