import '../App.css';

import {
  Col,
  Row,
  Card,
  Button,
  Modal
} from 'react-bootstrap';
import React, { useEffect, useState, Fragment } from 'react';
import "react-sliding-pane/dist/react-sliding-pane.css";
import { MapkitProvider, Map, useMap, Marker } from 'react-mapkit'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used


import { PinDropContext } from '../Context'
import { useParams, useLocation } from 'react-router-dom';

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)


export default function Tags() {
  const { jwt } = React.useContext(PinDropContext)

  const params = useParams();


  const [pinName, setPinName] = useState(null);
  const [showHowUpgrade, setShowHowUpgrade] = useState(false);



  let query = useQuery();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }



  useEffect(() => {
    if (query.get("pinName")) {
      setPinName(query.get("pinName"))
    }

  }, [params])


  return (

    <div class="d-flex flex-column h-100 justify-content-center align-items-center">
      <span class="d-flex flex-column border-bottom m-5 p-3">
        <span class="title" style={{ fontSize: 40, paddingBottom: 8 }}>
          Upgrade for Pin Drop web access.
        </span>
        <span class="subtitle" style={{ fontSize: 20, paddingBottom: 24, color: "#3A3E44" }}>
          Choose from one of the plans below to enjoy Pin Drop on the web.
        </span>
      </span>


      <Row className="g-3 w-80">

        <Col lg={4}>

          <Card >
            <Card.Body>
              <Card.Title style={{ color: "#54AD95", fontSize: 24, paddingBottom: 8, fontWeight: "700" }}>Free</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Your current plan</Card.Subtitle>
              <Card.Subtitle className="mb-2 text-muted">$0 free forever</Card.Subtitle>
              <Card.Text>
                Enjoy all the basic features and functionality of Pin Drop for free.
              </Card.Text>
              <Button className="w-100 p-3" variant="light" onClick={() => setShowHowUpgrade(true)} >Current plan</Button>
            </Card.Body>
          </Card>
        </Col>


        <Col lg={4}>

          <Card >
            <Card.Body>
              <Card.Title style={{ color: "#54AD95", fontSize: 24, paddingBottom: 8, fontWeight: "700" }}>Lite</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">$4.99 / month</Card.Subtitle>
              <Card.Subtitle className="mb-2 text-muted">$49.99 / annual</Card.Subtitle>
              <Card.Text>
                Ideal for individuals to manage and share maps with more privacy.                  </Card.Text>
              <Button onClick={() => setShowHowUpgrade(true)} className="w-100 p-3" variant="primary">Upgrade to Lite</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>

          <Card >
            <Card.Body>
              <Card.Title style={{ color: "#203C67", fontSize: 24, paddingBottom: 8, fontWeight: "700" }}>Plus</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">$9.99 / month </Card.Subtitle>
              <Card.Subtitle className="mb-2 text-muted">$99.99 / annual </Card.Subtitle>
              <Card.Text>
                Perfect for small teams and professionals using maps for work.                  </Card.Text>
              <Button onClick={() => setShowHowUpgrade(true)} className="w-100 p-3" variant="primary">Upgrade to Plus</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>


      <span style={{ color: "rgba(58, 62, 68, 0.6)", paddingTop: 64 }}>Compare plan features and upgrade in the app · Cancel subscriptions at any time</span>

      <Modal show={pinName} onHide={() => setPinName(null)}
        animation={true}
        centered

      >
        <Modal.Header closeButton>
          <Modal.Title >
            Pin Saved
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 32 }}>
          <span className='title'>{pinName} has been saved</span>
          <span className='pt-2 subtitle'>Want to do more? Upgrade now for full web access</span>
          <span className='pt-2 alertbody'>Did you know you can do all the things on the Pin Drop app on the website?</span>
          <Button onClick={() => setPinName(null)} className="w-100 p-3 mt-4" variant="primary">Done</Button>
        </Modal.Body>

      </Modal>
      <Modal show={showHowUpgrade} onHide={() => setShowHowUpgrade(false)}
        animation={true}
        centered

      >
        <Modal.Header closeButton>
          <Modal.Title >
            How to upgrade
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 32 }}>
          <span className='alertbody'>Open Pin Drop on your mobile and goto your profile where you will be able to choose your plan.</span>
          <Button onClick={() => setShowHowUpgrade(false)} className="w-100 p-3 mt-4" variant="primary">Done</Button>
        </Modal.Body>

      </Modal>
    </div>





  );
}