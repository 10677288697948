import '../App.css';
import { Map, Marker } from 'react-mapkit'
import {
  Col,
  Button,
  Row,
  Table,
  InputGroup,
  FormControl,
  Image
} from 'react-bootstrap';
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from 'react';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { PinItem } from '../components/PinItem';

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default function Tags() {

  const { instance } = useMsal();
  const { accounts } = useMsal();

  const [jwt] = useState(sessionStorage.getItem("jwt"));
  const [orgs, setOrgs] = useState([]);
  const [org, setOrg] = useState(null);
  const [orgPeople, setOrgPeople] = useState([]);
  const [person, setPerson] = useState(null);
  const [tagMappings, setTagMappings] = useState([]);
  const [tag, setTag] = useState(false);
  const [tagPins, setTagPins] = useState([]);



  useEffect(() => {
    const viewingOrg = sessionStorage.getItem("org")
    if (viewingOrg) {
      console.log(viewingOrg)
      setOrg(JSON.parse(viewingOrg))
    }
  
  }, []);
  
  useEffect(() => {
    if (jwt && org) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: jwt, orgID: org.id })
      };
      fetch('https://apiv2.pindrop.it/orgUsers', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          setOrgPeople(data)
          // setPins(data.pins)
          // sessionStorage.setItem("orgs", JSON.stringify(data))
          // setTagMappings(data.relationships)
        });


    }
  }, [jwt, org]);

  return (
    <Col className={"map"}>


      <Row >
        <Col xs={12} md={9} lg={11}  >

          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
              Search
            </InputGroup.Text>
            <FormControl
              placeholder="Search for something"
              aria-label="Search for something"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

        </Col>
      </Row>

      <Row className="my-3">
        <Col xs={12} md={9} lg={11}  >
        <Button variant="primary">Add person</Button>
          
          </Col>
      </Row>

      <Col xs={12} md={9} lg={11} >

        <Table striped bordered hover>
          <thead>
            <tr>
            <th></th>
            <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orgPeople.map((p, i) => {
              return (
                <tr onClick={() =>           
                  setPerson(p)
                  
              }>
                  <td>
                    <Image src={`https://pindropbackup.blob.core.windows.net/img/${p.photo}`} rounded width={40} />
                  </td>
                  <td>{p.name}</td>
                  <td>{p.email}</td>
                  <td>{p.role}</td>
                  <td>Edit</td>

                </tr>
              )
            })}


          </tbody>
        </Table>
      </Col>
      <Row className="my-3">
        <Col xs={12} md={9} lg={11}  >
        <Button variant="primary">Add person</Button>
          
          </Col>
      </Row>

{person &&
      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={person}
        title={person.name}
        subtitle={person.email}
        onRequestClose={() => {
          setPerson(null);
        }}
        width={"30%"}
      >


      </SlidingPane>
}

    </Col>
    
  );
}