import '../App.css';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'

import { PinDropContext } from '../Context'
import { PinItem } from './PinItem';
import { TagItem } from './TagItem';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
var _ = require('lodash');


var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function PinChooser({ setSelectedPins, setSelectedTags, selectedPins, selectedTags, bulk }) {

    const { jwt, pins, tags, relationships, loadingPins, setLoadingPins } = React.useContext(PinDropContext)
    const [query, setQuery] = useState("")
    const [localPins, setLocalPins] = useState([])
    const [localTags, setLocalTags] = useState([])

    React.useEffect(() => {

        console.log("pins", selectedPins)
    }, [selectedPins])


    
    React.useEffect(() => {
        if (query.length == 0) {
            setLocalPins(pins)
            setLocalTags(tags)
        }
        if (query.length > 0 && !bulk) {
            setLocalPins(pins.filter(p => p.name.toLowerCase().includes(query.toLowerCase())))
            setLocalTags(tags.filter(t => t.name.toLowerCase().includes(query.toLowerCase())))
        }
        if (query.length > 0 && bulk) {
            let lpins = pins.filter(p => p.name.toLowerCase().includes(query.toLowerCase()))
            let ltags = tags.filter(t => t.name.toLowerCase().includes(query.toLowerCase())).map(t => t.id)


            let ltpins = pins.filter(p => p.tag_ids.filter(t => ltags.includes(t)).length > 0)

            setLocalPins(_.uniq(ltpins.concat(lpins)))


        }

    }, [query, pins, tags])

    return (
        <div>
            <div style={{

                borderRadius: 8, width: "100%",
                backgroundSize: "cover", backgroundColor: "rgba(0, 0, 0, 0.04)",
                padding: 16,
                marginBottom: 12,
            }}>
                {!bulk &&


                    <Tabs
                        defaultActiveKey="pins"
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                        variant="pills"
                        style={{ borderRadius: 8, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.04)", padding: 8, marginBottom: 12 }}
                    >
                        <Tab eventKey="pins" title="Pins">
                            <Form>
                                <Form.Group controlId="exampleForm.ControlInput1">

                                    <Form.Control type="Search" placeholder="Search pins"
                                        onChange={(e) => { setQuery(e.target.value); }}
                                        value={query}
                                        style={{ borderRadius: 10, height: 56, fontSize: 16, fontWeight: "400", color: "rgba(58, 62, 68, 0.6);", marginBottom: 16 }}
                                    />

                                </Form.Group>
                            </Form>

                            <div style={{ height: 500, overflowX: "auto" }} className='chooserPinList'>
                                {localPins.map(pin => {

                                    return (
                                        <PinItem key={pin.id} pin={pin} selectable={true}
                                            checked={selectedPins.find(s => s.id === pin.id)}
                                            onPress={() => {
                                                if (selectedPins.find(s => s.id === pin.id)) {
                                                    setSelectedPins(selectedPins.filter(s => s.id !== pin.id))
                                                } else {
                                                    setSelectedPins([...selectedPins, pin])
                                                }
                                            }
                                            } />
                                    )
                                }
                                )}

                            </div>
                        </Tab>
                        <Tab eventKey="tags" title="Tags">
                            <Form>
                                <Form.Group controlId="exampleForm.ControlInput1">

                                    <Form.Control type="Search" placeholder="Search tags"
                                        onChange={(e) => { setQuery(e.target.value); }}
                                        value={query}
                                        style={{ borderRadius: 10, height: 56, fontSize: 16, fontWeight: "400", color: "rgba(58, 62, 68, 0.6);", marginBottom: 16 }}
                                    />

                                </Form.Group>
                            </Form>

                            <div style={{ height: 500, overflowX: "auto" }}>
                                {localTags.map(tag => {

                                    return (
                                        <TagItem
                                            tag={tag}
                                            key={tag.id}
                                            selectable
                                            checked={selectedTags.find(s => s.id === tag.id)}
                                            onPress={() => {
                                                if (selectedTags.find(s => s.id === tag.id)) {
                                                    setSelectedTags(selectedTags.filter(s => s.id !== tag.id))
                                                } else {
                                                    setSelectedTags([...selectedTags, tag])
                                                }
                                            }
                                            } />

                                    )
                                }
                                )}

                            </div>
                        </Tab>

                    </Tabs>
                }
                {bulk &&

                    <div style={{}}>
                        <Form>
                            <Form.Group controlId="exampleForm.ControlInput1">

                                <Form.Control type="Search" placeholder="Search pins"
                                    onChange={(e) => { setQuery(e.target.value); }}
                                    value={query}
                                    style={{ borderRadius: 10, height: 56, fontSize: 16, fontWeight: "400", color: "rgba(58, 62, 68, 0.6);", marginBottom: 16 }}
                                />

                            </Form.Group>
                            <a href="#" onClick={() => setSelectedPins([]) } style={{ fontSize: 12, color: "rgba(58, 62, 68, 0.6);", marginBottom: 16 }}>deselect all</a>&nbsp;
                            <a href="#" onClick={() => setSelectedPins(localPins) } style={{ fontSize: 12, color: "rgba(58, 62, 68, 0.6);", marginBottom: 16 }}>select all</a>
                        </Form>

                        <div style={{ height: 500, overflowX: "auto" }} className='chooserPinList'>
                            {localPins.map(pin => {

                                return (
                                    <PinItem key={pin.id} pin={pin} selectable={true}
                                        
                                        checked={selectedPins.find(s => s.id === pin.id)}
                                        onPress={() => {
                                            if (selectedPins.find(s => s.id === pin.id)) {
                                                setSelectedPins(selectedPins.filter(s => s.id !== pin.id))
                                            } else {
                                                setSelectedPins([...selectedPins, pin])
                                            }
                                        }
                                        } />
                                )
                            }
                            )}

                        </div>
                    </div>
                }
            </div>

        </div>

    );
}