import '../App.css';

import {
  Col,
  Row,
  Card,
  Button,
  Modal
} from 'react-bootstrap';
import React, { useEffect, useState, Fragment } from 'react';
import "react-sliding-pane/dist/react-sliding-pane.css";
import { MapkitProvider, Map, useMap, Marker } from 'react-mapkit'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used


import { PinDropContext } from '../Context'
import { useParams, useLocation } from 'react-router-dom';

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)


export default function Tags() {
  const { jwt } = React.useContext(PinDropContext)

  const params = useParams();


  const [pinName, setPinName] = useState(null);
  const [showHowUpgrade, setShowHowUpgrade] = useState(false);



  let query = useQuery();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }



  useEffect(() => {
    if (query.get("pinName")) {
      setPinName(query.get("pinName"))
    }

  }, [params])


  return (

    <div class="d-flex flex-column h-100 justify-content-center align-items-center">
      <span class="d-flex flex-column border-bottom m-3 p-3 text-center">
        <span class="title" style={{ fontSize: 40, paddingBottom: 8 }}>
          Get Pin Drop on your mobile
        </span>
        <span class="subtitle" style={{ fontSize: 20, paddingBottom: 24, color: "#3A3E44" }}>
          You need the Pin Drop mobile app to login on the web.
        </span>
      </span>

      <span class="d-flex flex-column p-3 text-center">
        <div >
          <center>
            <a href="https://apps.apple.com/us/app/pin-drop-custom-private-maps/id425356789">
              <img src="/images/AppStore.png" width="120" style={{ marginRight: 16 }} />
            </a>

            <a href="https://play.google.com/store/apps/details?id=com.pindropapp">
              <img src="/images/GooglePlay.png" width="120" />
            </a>
          </center>
        </div>
      </span>







    </div>





  );
}