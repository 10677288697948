import React, { createContext, useEffect, useState } from 'react';
import RBush from 'rbush';
var georbush = require('georbush');


export const PinDropContext = createContext({});
export const PinDropProvider = ({ children }) => {

    const [tags, setTags] = useState([]);
    const [pins, setPins] = useState([]);
    const [media, setMedia] = useState([]);
    const [relationships, setRelationships] = useState([]);
    const [jwt, setJWTContext] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [migrating, setMigrating] = useState(false);
    const [loadingPins, setLoadingPins] = useState(false);
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState(null);
    const [rBush, setRBush] = useState(new RBush());
    const [refreshPins, setRefreshPins] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [miles, setMilesContext] = useState(false);


    // useEffect(() => {


    //       if (location && rBush.all().length > 0) {
    //         setPins(georbush.around(rBush, location.latitude, location.longitude))
    //       }


    // }, [location, rBush])

    // useEffect(() => {
    //     if  (pinsO.length > 0 && tags.length > 0 && relationships.length > 0 && rBush.all().length == 0) {



    //     }
    //     setPins(rBush.all())
    //     // if (location) {
    //     //     // const lpins = georbush.around(rBush, location.latitude, location.longitude)
    //     //     setPins(rBush.all())
    //     // } else {
    //     //     setPins(rBush.all())
    //     // }
    // }, [pins, tags, relationships, location])

    const setJWT = (token) => {
        if (token) {
            setJWTContext(token);
            localStorage.setItem("jwt", token)
            console.log("Saved JWT")
        } else {
            console.log("Cleared JWT")
            setJWTContext(null)
        }
    }

    const setMiles = (isMiles) => {
        if (isMiles) {
            setMilesContext(true);
            localStorage.setItem("units", "miles")
            console.log("Saved Miles")
        } else {
            console.log("Saved KM")
            setMilesContext(false)
            localStorage.setItem("units", "km")
        }
    }

    const logout = () => {
        setJWTContext(null);
        console.log("Logged out")

    }

    React.useEffect(() => {

        const jwt = localStorage.getItem("jwt")
        if (jwt) {
            setJWTContext(jwt)
        }





        console.log("Context complete")
    }, []);

    return (
        <PinDropContext.Provider
            value={{
                tags,
                setTags,
                pins,
                setPins,
                media,
                setMedia,
                relationships,
                setRelationships,
                jwt,
                setJWT,
                subscription,
                setSubscription,
                migrating, setMigrating,
                logout,
                loadingPins, setLoadingPins,
                loading, setLoading,
                location, setLocation,
                rBush, setRBush,
                refreshPins, setRefreshPins,
                profilePhoto, setProfilePhoto,
                miles, setMiles

            }}>
            {children}
        </PinDropContext.Provider>
    );
};

