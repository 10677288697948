import '../App.css';
import { Map, Marker, useMap } from 'react-mapkit'
import {
  Col,
  Form,
  Button,
  Row,
  Alert
} from 'react-bootstrap';
import React, { useEffect, useState, Fragment } from 'react';

import { Pin } from '../components/Pin';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { PinItem } from '../components/PinItem';
import { Search } from '../components/Search';
import { NewPin } from '../components/NewPin';
import { BulkEdit } from '../components/BulkEdit';
import { best } from 'wcag-color'
import { PinDropContext } from '../Context'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ShimmerText } from "react-shimmer-effects";
import Icon from "../Icon";
import { useNavigate, useParams, useLocation } from 'react-router-dom';


var georbush = require('georbush');


export default function Pins() {


  const { jwt, pins, location, tags, relationships, loadingPins, rBush, refreshPins, subscription } = React.useContext(PinDropContext)

  const [showMap, setShowMap] = useState(true);
  const [newPin, setNewPin] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const { map, mapProps, setRotation, mapkit } = useMap()

  const [selectedPinID, setSelectedPinID] = useState(null);
  const [showSearch, setShowSearch] = useState(true);
  const [bulkPins, setBulkPins] = useState(false);
  const [evSet, setEVSet] = useState(false);
  // const [pins, setPins] = useState([])
  const params = useParams();
  let navigate = useNavigate();
  const [markers, setMarkers] = useState([]);
  const [showUpgrade, setShowUpgrade] = useState(false);



  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


  let query = useQuery();


  const [org, setOrg] = useState(null);

  useEffect(() => {
    const viewingOrg = sessionStorage.getItem("org")
    if (viewingOrg) {
      console.log(viewingOrg)
      setOrg(JSON.parse(viewingOrg))
    }

  }, []);

  useEffect(() => {
    if (params && params.id && params.id !== "new") {
      setSelectedPinID(params.id)
      navigate('/pins')
    }

    if (params && params.id && params.id == "new") {
      if (query.get("name")) {
        setNewPin({ lat: parseFloat(query.get("lat")), lng: parseFloat(query.get("lng")), name: query.get("name") })
      }
      if (query.get("address") && mapkit) {

        var geocoder = new mapkit.Geocoder({
          language: "en-GB",
          getsUserLocation: true
        }).lookup(query.get("address"), (error, data) => {
          if (error) {
            alert(error)
          }
          if (data) {
            console.log(data.results[0])
            setNewPin({ lat: data.results[0].coordinate.latitude, lng: data.results[0].coordinate.longitude, name: query.get("title"), address: query.get("address"), description: query.get("description") })
          }
        }
        )
      }

    }

  }, [params, mapkit]);


  // useEffect(() => {
  //   if (rBush) {

  //     console.log("RBUSH Refresh", rBush.all().length)
  //     if (location) {
  //       setPins(georbush.around(rBush, location.latitude, location.longitude))
  //     }

  //     if (!location) {
  //       setPins(rBush.all())
  //     }
  //   }
  // }, [rBush, location, refreshPins]);


  useEffect(() => {
    if (map && showPin) {
      var newCenter = new mapkit.Coordinate(showPin.lat, showPin.lng);
      var span = new mapkit.CoordinateSpan(.01);
      var region = new mapkit.CoordinateRegion(newCenter, span);
      map.setRegionAnimated(region)
    }

  }, [showPin, map])

  // useEffect(() => {

  //   if (map && mapkit) {

  //     if (location) {
  //       var newCenter = new mapkit.Coordinate(location.latitude, location.longitude);
  //       var span = new mapkit.CoordinateSpan(.2);
  //       var region = new mapkit.CoordinateRegion(newCenter, span);
  //       map.setRegionAnimated(region)
  //     }

  //     map.showsUserLocation = true
  //     // map.tracksUserLocation = true

  //     if (!evSet) {
  //       setEVSet(true)


  //     map.addEventListener("select", function configurationChanged(ev) {
  //       if (ev && ev.annotation) {
  //       setSelectedPinID(ev.annotation.data)
  //       }
  //     });

  //     map.addEventListener("single-tap", function configurationChanged(ev) {
  //       setSelectedPinID(null)
  //       setShowPin(false)
  //     });

  //     map.addEventListener("long-press", function configurationChanged(ev) {
  //       let coord = map.convertPointOnPageToCoordinate(ev.pointOnPage)
  //       console.log(coord.latitude, coord.longitude)
  //       var geocoder = new mapkit.Geocoder({
  //         language: "en-GB",
  //         getsUserLocation: true
  //       }).reverseLookup(new mapkit.Coordinate(coord.latitude, coord.longitude), (err, data) => {
  //         console.log(data.results[0].formattedAddress);
  //         setShowSearch(false)
  //         setNewPin({ lat: coord.latitude, lng: coord.longitude, address: data.results[0].formattedAddress })

  //       });


  //     });

  //   }
  //     // map.setRegion({ latitude: location.lat, longitude: location.lng, latitudeDelta: 0.01, longitudeDelta: 0.01 })


  //   }
  // }, [map, mapkit, location]);

  useEffect(() => {
    if (map && selectedPinID) {

      const selectedPin = pins.find(pin => pin.id == selectedPinID)

      if (selectedPin) {
        var newCenter = new mapkit.Coordinate(selectedPin.lat, selectedPin.lng);
        var span = new mapkit.CoordinateSpan(.01);
        var region = new mapkit.CoordinateRegion(newCenter, span);
        map.setRegionAnimated(region)
        if (selectedPin) {
          let ann = map.annotations.find(a => a.data === selectedPin.id)
          ann.selected = true
        } else {
          map.annotations.forEach(a => a.selected = false)
        }
      }
    }

  }, [selectedPinID, map])

  useEffect(() => {

    if (map && mapkit) {

      // if (location) {
      //   var newCenter = new mapkit.Coordinate(location.latitude, location.longitude);
      //   var span = new mapkit.CoordinateSpan(.2);
      //   var region = new mapkit.CoordinateRegion(newCenter, span);
      //   map.setRegionAnimated(region)
      // }

      map.showsUserLocation = true

      console.log("Adding Pins", pins.length)

      if (pins.length > 0 && markers.length == 0) {
        const ms = []
        // map.removeAnnotations(markers)

        setMarkers([])

        pins.map(pin => {
          let pTag = tags.find(t => pin.tag_ids.includes(t.id))
          var newCoord = new mapkit.Coordinate(pin.lat, pin.lng);
          var newMarker = new mapkit.MarkerAnnotation(
            newCoord

          );

          newMarker.title = pin.title;
          newMarker.data = pin.id
          newMarker.color = tags.find(t => pin.tag_ids.includes(t.id)) ? tags.find(t => pin.tag_ids.includes(t.id)).color : "black"
          newMarker.glyphColor = "white"
          newMarker.glyphImage = {
            1: (pTag && pTag.icon) ? `/mapIcons/${pTag.icon}.svg` : "/Icon/MapPinLight.png",
            2: (pTag && pTag.icon) ? `/mapIcons/${pTag.icon}.svg` : "/Icon/MapPinLight@2x.png",
            3: (pTag && pTag.icon) ? `/mapIcons/${pTag.icon}.svg` : "/Icon/MapPinLight@3x.png"
          }
          newMarker.clusteringIdentifier = "me"

          ms.push(newMarker)
          map.addAnnotation(newMarker)
        })

        map.showItems(ms, {
          animate: true,
          padding: new mapkit.Padding(60, 25, 60, 25)
        })

        setMarkers(ms)



      }

      if (!evSet) {
        setEVSet(true)


        map.addEventListener("select", function configurationChanged(ev) {
          if (ev && ev.annotation) {
            setSelectedPinID(ev.annotation.data)
          }
        });

        map.addEventListener("single-tap", function configurationChanged(ev) {
          setSelectedPinID(null)
          setShowPin(false)
        });

        map.addEventListener("long-press", function configurationChanged(ev) {
          let coord = map.convertPointOnPageToCoordinate(ev.pointOnPage)
          console.log(coord.latitude, coord.longitude)
          var geocoder = new mapkit.Geocoder({
            language: "en-GB",
            getsUserLocation: true
          }).reverseLookup(new mapkit.Coordinate(coord.latitude, coord.longitude), (err, data) => {
            console.log(data.results[0].formattedAddress);
            setShowSearch(false)
            setNewPin({ lat: coord.latitude, lng: coord.longitude, address: data.results[0].formattedAddress })

          });


        });

      }



    }
  }, [map, mapkit, location, pins]);


  const columns = [{
    dataField: 'name',
    text: 'Name',
    sort: true

  }, {
    dataField: 'date_dropped',
    text: 'Dropped',
    sort: true

  }];


  useEffect(() => {


    if (selectedPinID) {
      setShowSearch(false)
      setShowPin(pins.find(p => p.id == selectedPinID))


    }
  }, [selectedPinID, pins]);

  return (
    <Row className={"map"} style={{ width: "100%", height: "90%" }}>
      <Col >



        <Search map={map} mapkit={mapkit} />

        <Map tracksUserLocation id={"mapPins"} key={"mapPins"} center={[location ? location.latitude : 0, location ? location.longitude : 0]} {...mapProps} showsUserLocation>

        </Map>

      </Col>

      <div class="pinListContainer">
        <div class="d-flex justify-content-between" style={{ paddingLeft: 8 }}>
          <div class=" d-flex justify-content-start title">
            Pins
          </div>
          {!loadingPins &&
            <div class="d-flex justify-content-end" style={{ marginRight: -16 }}>
              <div class="d-flex justify-content-end" style={{ paddingRight: 16 }}>
                <a href="#" onClick={() => {
                  setBulkPins(true)
                }} >
                  <Icon icon="Edit" size={40} />
                </a>
              </div>
              <div class="d-flex justify-content-end" style={{ paddingRight: 0 }}>
                <a href="#" onClick={() => {
                  setNewPin(true)
                }} >
                  <Icon icon="BigPlus" size={40} />
                </a>
              </div>
            </div>
          }
        </div>

        <div class="pinList" >


          {loadingPins &&
            <div style={{ width: 380 }}>
              <ShimmerText line={5} gap={10} />
              <ShimmerText line={5} gap={10} />
              <ShimmerText line={5} gap={10} />
              <ShimmerText line={5} gap={10} />
              <ShimmerText line={5} gap={10} />
              <ShimmerText line={5} gap={10} />
              <ShimmerText line={5} gap={10} />
              <ShimmerText line={5} gap={10} />

            </div>
          }

          {pins.length < 3 && !loadingPins &&
            <div style={{ padding: 32, backgroundColor: "#2D9CDB", borderRadius: 12, width: 380, marginTop: 32, marginBottom: 32 }}>
              <p style={{ color: "white", fontWeight: "700", fontSize: 18 }}>Can't see all your pins?</p>
              <p style={{ color: "white", fontWeight: "400", fontSize: 15 }}>
                It may be the migration didn't work first time round.  Go to <span style={{ fontWeight: "700" }} > Managed Data > Resync Data</span> on the mobile app to try again.
              </p>

            </div>
          }
          {!loadingPins && pins && relationships && tags && pins.map((pin, i) => {
            return (
              <PinItem
                pin={pin}
                map={map}
                last={i == pins.length - 1}
                onPress={() => {
                  setSelectedPinID(pin.id)
                }}

              />
            )
          })
          }
        </div>
      </div>

      <Offcanvas show={showPin}
        placement={"end"}
        backdrop={false}
        onHide={() => {
          setShowPin(false);
          setSelectedPinID(null);

          // setShowSearch(true);

        }}
        style={{ width: 410, padding: 0 }}>
        <Offcanvas.Body style={{ padding: 0 }}>
          {showPin &&
            <>

              <Pin pin={showPin} onClose={() => {
                setShowPin(false);
                setSelectedPinID(null);
              }} />
            </>
          }
        </Offcanvas.Body>
      </Offcanvas>

      {
        newPin &&
        <NewPin show={newPin} onClose={() => {
          setNewPin(false);
        }} visible={newPin} newPin={newPin} map={map} mapkit={mapkit} />
      }

      {
        bulkPins &&
        <BulkEdit show={bulkPins} onClose={() => {
          setBulkPins(false);
        }} visible={bulkPins} />
      }






    </Row >
  );
}