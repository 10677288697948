import '../App.css';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import { PinDropContext } from '../Context'
import { useIntervalWhen } from "rooks";
import { v4 as uuidv4 } from 'uuid';
import { QRCode } from 'react-qrcode-logo';
import jwt_decode from "jwt-decode";
import { API_URL } from '../vars'
import { useNavigate } from 'react-router-dom';


var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default function QRCodeFrame() {

  const { setSubscription, setJWT } = React.useContext(PinDropContext)
  const [uuidAuth, setUUIDAuth] = useState(null);
  const [booleanState, setBooleanState] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {

    // setJWT(sessionStorage.getItem("jwt"))


    setUUIDAuth(uuidv4())
  }, []);


  useEffect(() => {


    const sJwt = localStorage.getItem("jwt")

    if (sJwt) {
      setBooleanState(false)
      navigate('/pins')
    }

  }, []);

  useIntervalWhen(
    () => {
      fetch(`${API_URL}/weblogin?token=${uuidAuth}`, {}).then(res => res.json()).then(data => {

        if (data.jwt) {
          setJWT(data.jwt)
          localStorage.setItem("jwt", data.jwt)
          setBooleanState(false);
          window.top.location.href = "/pins";
        }

      }).catch(err => {
        console.log(err);
      })
    },
    5000, // run callback every 1 second
    booleanState, // start the timer when it's true
    true // no need to wait for the first interval
  );


  return (
    <div style={{ backgroundColor: "white", overflow: "hidden" }}>
      {uuidAuth &&
        <QRCode value={`https://pindrop.me/login/${uuidAuth}`} size={250}
          logoImage="/qrlogo.png"
          logoWidth={50}
          logoHeight={50}
          eyeRadius={[
            15,  // top/left eye
            15, // top/right eye
            15,  // bottom/left eye
          ]}

        />
      }
    </div>



  );
}