import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.css';

import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  HashRouter,
  Routes,
  Route,
  Switch
} from "react-router-dom";

import Pins from "./routes/pins";
import Tags from "./routes/tags";
import Orgs from "./routes/orgs";
import Sync from "./routes/sync";
import Groups from "./routes/groups";
import People from "./routes/people";
import QRCode from "./routes/qrcode";
import Login from "./routes/login";
import Upgrade from "./routes/upgrade";
import './custom.scss';
import './App.css';


import Collections from "./routes/collections";
import { MapkitProvider } from 'react-mapkit'
import { QueryClient, QueryClientProvider, onlineManager } from 'react-query'


import { PinDropProvider } from './Context'

// import 'bootstrap/dist/css/bootstrap.min.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
    },
  },
})


ReactDOM.render(
  <React.StrictMode>
    <MapkitProvider tokenOrCallback={"eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlFaRlY4M0o4UkIifQ.eyJpc3MiOiI4TEdXUjlXNE1MIiwiaWF0IjoxNjc3MjYwNzgzLCJleHAiOjE3MDg2NDY0MDB9.DVbs2SnSSags-YAhgrGuKN-ShTtIbCaNRYCzkP2LXkgEg3bYtczSpwZvS5ctvXCu-7Uk9KTleXjaop0KSxtf2g"}>
      <QueryClientProvider client={queryClient}>
        <PinDropProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/qrcode" element={<QRCode />} />
              <Route path="/login" element={<Login />} />

              <Route path="/" element={<App />}>
                <Route path="pins" element={<Pins />} />
                <Route path="/pins/:id" element={<Pins />} />
                <Route path="/tags/:id" element={<Tags />} />

                <Route path="tags" element={<Tags />} />
                <Route path="upgrade" element={<Upgrade />} />
                <Route path="groups" element={<Groups />} />
                <Route path="orgs" element={<Orgs />} />
                <Route path="people" element={<People />} />
                <Route path="collections" element={<Collections />} />
                <Route path="sync" element={<Sync />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </PinDropProvider>
      </QueryClientProvider>
    </MapkitProvider>
  </React.StrictMode>,
  document.getElementById("root")
);



