import '../App.css';
import {
  Col,
  Row,
  Card,
  Container
} from 'react-bootstrap';
import axios from "axios";

import React, { useEffect, useState } from 'react';
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useNavigate } from 'react-router-dom';
import { PinDropContext } from '../Context'

import { Map, Marker, useMap } from 'react-mapkit'
import { best } from 'wcag-color'
import { Search } from '../components/Search';
import { Collection } from '../components/Collection';
import { NewCollection } from '../components/NewCollection';
import Icon from '../Icon';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useQuery } from "react-query";

import dayjs from 'dayjs'
import { faLacrosseStick } from '@fortawesome/pro-thin-svg-icons';
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default function Collections() {


  const [newCollection, setNewCollection] = useState(false);
  const [org, setOrg] = useState(null);
  const [addCollection, setAddCollection] = useState(false);
  const [addCollectionTemplate, setAddCollectionTemplate] = useState(false);
  const [collections, setCollections] = useState([]);
  const [orgPeople, setOrgPeople] = useState([]);
  const [collectionTemplates, setCollectionTemplates] = useState([]);
  const [collection, setCollection] = useState(null);
  const [tagMappings, setTagMappings] = useState([]);
  const [tag, setTag] = useState(false);
  const [collectionPins, setCollectionPins] = useState([]);
  const [localLogo, setLocalLogo] = useState();
  const [backgroundPhoto, setBackgroundPhoto] = useState();
  const [showCollection, setShowCollection] = useState(false);
  const [saving, setSaving] = useState(false);
  const { map, mapProps, setRotation, mapkit } = useMap()


  const [remoteLogo, setRemoteLogo] = useState();
  const [remoteBackgroundPhoto, setRemoteBackgroundPhoto] = useState();

  const [collectionTitle, setCollectionTitle] = useState("");
  const [updateList, setUpdateList] = useState(false);
  let navigate = useNavigate();



  const { location, tags, jwt } = React.useContext(PinDropContext)


  function useCollections() {
    return useQuery(["collections"], async () => {
      const { data } = await axios.post('https://apiv2.pindrop.it/getCollections', { token: jwt })
      return data;
    });
  }

  const { isLoading, isError, data, error, refetch } = useCollections()



  useEffect(() => {
    if (jwt && org) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: jwt, orgID: org.id })
      };
      fetch('https://apiv2.pindrop.it/orgUsers', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          setOrgPeople(data)
        });


    }
  }, [jwt, org]);



  useEffect(() => {
    if (jwt && org) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: jwt, orgID: org.id })
      };
      fetch('https://apiv2.pindrop.it/orgCollections', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log("collections", data)
          setCollections(data)
        });


    }
  }, [jwt, org, updateList]);




  return (

    <Row className={"map"} style={{ width: "100%", height: "90%" }}>
      <Col >



        <Search map={map} mapkit={mapkit} />

        <Map tracksUserLocation center={[location ? location.latitude : 0, location ? location.longitude : 0]} {...mapProps} showsUserLocation>

          {false && collectionPins.map((pin, i) => {
            if (pin.lat && pin.lng) {
              return (<Marker
                latitude={parseFloat(pin.lat)}
                longitude={parseFloat(pin.lng)}
                key={i}
                title={pin.name}
                data={pin.id}
                color={tags.find(t => pin.tag_ids.includes(t.id)) ? tags.find(t => pin.tag_ids.includes(t.id)).color : "black"}
                glyphColor={best('#ffffff', '#3A3E44', '#000000')}
                glyphImage={{ 1: "/Icon/MapPinLight.png", 2: "/Icon/MapPinLight@2x.png", 3: "/Icon/MapPinLight@3x.png" }}
                clusteringIdentifier={"me"}

              />)
            }
          })}
        </Map>

      </Col>
      <div class="pinListContainer" >
        <div class="d-flex justify-content-between" style={{ paddingLeft: 8 }}>
          <div class=" d-flex justify-content-start title">
            Collections
          </div>
          {!isLoading &&
            <div class="d-flex justify-content-end" style={{ marginRight: -16 }}>
              <a href="#" onClick={() => {
                setNewCollection(true)
              }} >
                <Icon icon="BigPlus" size={40} />
              </a>
            </div>
          }
        </div>

        <div class="collectionList" >
          {isLoading &&
            <div style={{ paddingLeft: 10 }}>
              <Row xs={1} md={2} className="g-2">
                <ShimmerThumbnail height={187} width={187} rounded />
                <ShimmerThumbnail height={187} width={187} rounded />
                <ShimmerThumbnail height={187} width={187} rounded />
                <ShimmerThumbnail height={187} width={187} rounded />
                <ShimmerThumbnail height={187} width={187} rounded />
                <ShimmerThumbnail height={187} width={187} rounded />
                <ShimmerThumbnail height={187} width={187} rounded />
                <ShimmerThumbnail height={187} width={187} rounded />
                <ShimmerThumbnail height={187} width={187} rounded />
                <ShimmerThumbnail height={187} width={187} rounded />


              </Row>
            </div>}

          {!isLoading &&
            <div style={{ paddingLeft: 10, paddingTop: 16 }}>
              {/* <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="home" title="My Collections">

      </Tab>
      <Tab eventKey="profile" title="Curated">

      </Tab>
    </Tabs> */}

              <Row xs={1} md={2} className="g-2">


                {data.map((c, i) => {
                  return (
                    <Card style={{ cursor: "pointer", borderRadius: 12, width: 175, height: 175, padding: 0, marginRight: 16, marginBottom: 8 }}
                      onClick={() => setShowCollection(c)}>
                      <Card.Img style={{
                        borderRadius: 12, filter: "brightness(0.6)",
                        objectFit: "cover"
                      }} variant="top" height={175} width={175}
                        src={`https://pindropbackup.blob.core.windows.net/${org ? (org.orgParent ? org.orgParent : org.id) : c.id}/${org ? c.backgroundPhoto : c.backgroundPhoto}`} />
                      <Card.ImgOverlay style={{}}>
                        <Container class="flex-column d-flex align-items-center justify-content-center" style={{ height: "100%", width: "100%" }}>
                          <div class="flex-column d-flex align-items-center justify-content-center" style={{ marginTop: 40, marginBottom: 0 }}>
                            {org &&
                              <img src={`https://pindropbackup.blob.core.windows.net/${org ? (org.orgParent ? org.orgParent : org.id) : c.id}/${org ? c.logo : c.backgroundPhoto}`} width={"100"} />
                            }
                          </div>

                          <div class="flex-column d-flex align-items-center justify-content-center" style={{}}>
                            <span style={{ fontSize: 17, color: "white", fontWeight: "700", textAlign: "center" }}>{c.name}</span>
                            <span style={{ fontSize: 13, color: "white", fontWeight: "400", textAlign: "center" }}>by <span style={{ fontWeight: "700" }}>{c.owner}</span></span>
                            <span style={{ fontSize: 13, color: "white", fontWeight: "400", textAlign: "center" }}>Collection · {c.pins} {c.pins == 1 ? "pin" : "pins"}</span>
                          </div>
                          <div class="flex-column d-flex align-items-center justify-content-center" style={{}}>

                          </div>
                          <div >

                            {/* <p style={{ fontStyle: collectionMessage.length == 0 ? "italic" : "inherit", fontFamily: "Inter", fontSize: 16, color: "white", fontWeight: "400", textAlign: "center" }}>{collectionMessage.length > 0 ? collectionMessage.replace("{{name}}", "Samantha") : "Add a personalised message..."}</p> */}
                          </div>
                        </Container>
                      </Card.ImgOverlay>
                    </Card>
                  )
                })}
              </Row>
            </div>
          }
        </div>
      </div>

      <Offcanvas show={showCollection}
        placement={"end"}
        backdrop={false}
        onHide={() => {
          setShowCollection(false);


        }}


        style={{ width: 410, padding: 0 }}>
        <Offcanvas.Body style={{ padding: 0 }}>
          {showCollection &&
            <>
              <Collection collection={showCollection} data={[]} onClose={() => setShowCollection(false)} map={map} mapkit={mapkit} />

            </>
          }
        </Offcanvas.Body>
      </Offcanvas>

      {/* <NewTag show={newTag} onClose={() => {
        setNewTag(false);
      }} visible={newTag}  /> */}





      {newCollection &&
        <NewCollection show={newCollection} onClose={() => {
          setNewCollection(false);
        }} visible={newCollection} newPin={newCollection} map={map} mapkit={mapkit} />
      }


    </Row>


  );
}