import '../App.css';
import { Map, Marker } from 'react-mapkit'
import {
  Col,
  Container,
  Row,
  Table,
  InputGroup,
  FormControl,
  Alert
} from 'react-bootstrap';
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from 'react';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { PinItem } from '../components/PinItem';

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default function Tags() {

  const { instance } = useMsal();
  const { accounts } = useMsal();

  const [jwt] = useState(sessionStorage.getItem("jwt"));
  const [orgs, setOrgs] = useState([]);
  const [org, setOrg] = useState(null);
  const [tagMappings, setTagMappings] = useState([]);
  const [tag, setTag] = useState(false);
  const [tagPins, setTagPins] = useState([]);
  const [orgSwitch, setOrgSwitch] = useState(false);



  useEffect(() => {
    console.log(org)
  }, [org]);

  useEffect(() => {
    if (jwt) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: jwt, admin: true })
      };
      fetch('https://apiv2.pindrop.it/getOrg', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          setOrgs(data)
          // setPins(data.pins)
          sessionStorage.setItem("orgs", JSON.stringify(data))
          // setTagMappings(data.relationships)
        });


    }
  }, [jwt]);

  return (
    <>

      {orgSwitch &&
        <Alert variant="primary">
          You are now interacting with <strong>{orgSwitch}</strong> organisation
        </Alert>
      }

      <Row >
        <Col xs={12} md={9} lg={12}  >

          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
              Search
            </InputGroup.Text>
            <FormControl
              placeholder="Search for something"
              aria-label="Search for something"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

        </Col>
      </Row>

      <Col xs={12} md={9} lg={12} >

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Domain</th>
              <th>Role</th>
              <th>Members</th>
              <th>Org. type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orgs.filter(o => o.isOrg).map((o, i) => {
              return (
                <tr onClick={() =>           
                  {
                    sessionStorage.setItem("org", JSON.stringify(o))
                    setOrgSwitch(o.name)
                  }
              }>
                  <td>{o.name}</td>
                  <td>{o.domain}</td>
                  <td>{o.role}</td>
                  <td>{o.members}</td>
                  <td>
                    {o.isCrew && "Crew" }
                    {o.isCollection && "Collection" }
                    {o.isOrg && "Organisation" }
                  </td>
                  <td>Edit</td>

                </tr>
              )
            })}


          </tbody>
        </Table>
      </Col>
{org &&
      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={org}
        title={org.name}
        subtitle={dayjs(tag.date_updated).format('D MMM YYYY')}
        onRequestClose={() => {
          setOrg(null);
        }}
        width={"30%"}
      >


      </SlidingPane>
}

    </>
  );
}